/*--------------------------
Call To Action Wrapper
-----------------------------*/
.call-to-action {
    position: relative;
    z-index: 2;
    .call-to-action-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url('https://animusdevelopment.b-cdn.net/images/bg/spray-smear-2.webp') ;
      background-position: top;
      background-size: cover;
      overflow: hidden;
      z-index: -99999999;
    }
}
