/*--------------------
Footer Styles
----------------------*/

.footer-wrapper {
    position: relative;
}

.animus-footer {
  display: inline-block;
  padding: 20px 20px;
  @media #{$sm-layout} {
    padding: 20px 20px 20px 20px;
  }
  .footer-image {
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: url('https://animusdevelopment.b-cdn.net/images/bg/footer-5.png') ;
    background-position: center;
    background-size: cover;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 99999999;

  }
    .footer-link {
      position: relative;
      @media #{$sm-layout} {
        text-align: left !important;
      }
      @media #{$md-layout} {
        text-align: left !important;
      }
        h4 {
            text-shadow: 2px 2px #000000;
            color: #ffffff;
            font-weight: 600;
            margin-bottom: 30px;
            @media #{$sm-layout} {
                text-shadow: none;
                margin-bottom: 14px;
            }
        }
        ul {
            &.ft-link {
                padding: 0;
                margin: 0;
                list-style: none;
                li {
                    a {
                        text-shadow: 2px 2px #000000;
                        color: #efefef;
                        font-size: 18px;
                        @extend %transition;
                        @media #{$sm-layout} {
                            text-shadow: none;
                            margin-bottom: 14px;
                        }
                    }
                    &:hover {
                        a {
                            color: $theme-color;
                        }
                    }
                    & + li {
                        margin-top: 13px;
                        @media #{$sm-layout} {
                            margin-top: 8px;
                        }
                    }
                }
            }
        }
    }
}


/* Footer Style Two  */
.footer-style-2 {
    p {
        color: #ffffff;
        font-size: 14px;
        opacity: 0.75;
    }
}





.copyright-text {
    padding-top: 33px;
    text-align: center;
    @media #{$sm-layout} {
        position: static;
        bottom: 0;
        padding-top: 33px;
    }

    @media #{$md-layout} {
        position: static;
        bottom: 0;
        padding-top: 33px;
    }
    p {
      text-align: center;
        color: #ffffff;
        text-shadow: 1px 1px #000000;
        font-size: 16px;
        opacity: 0.75;
    }
}

/*================================================
Footer Area CSS
=================================================*/
.footer-image {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0,0,0,0.4) ;
  -webkit-mask-image: linear-gradient(to bottom, transparent 10%, black 40%);
  mask-image: linear-gradient(to bottom, transparent 10%, black 40%);
  // background-image: url('/assets/images/bg/footer.webp');
  background-position: center;
  background-size: cover;
  overflow: hidden;
  top: 0;
  left: 0;
  // z-index: -99999999;
}
.footer-area {
    padding-top: 200px;
    position: relative;
    z-index: 99999;
    @media #{$sm-layout} {
        padding-top: 100px;
    }
}
.single-footer-widget {
    margin-bottom: 30px;
    @media #{$sm-layout} {
        padding-left: 0px !important;
    }

    .logo {
        margin-bottom: 15px;
        font-size: 15px;
        display: inline-block;
    }
    .social-link {
        padding-left: 0;
        list-style-type: none;
        margin: {
            bottom: 0;
            top: 18px
        };
        li {
            display: inline-block;
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
            a {
                width: 30px;
                height: 30px;
                text-align: center;
                color: #d7d7d7;
                background-color: #6b6b84;
                font-size: 18px;
                position: relative;
                border-radius: 2px;

                i {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    right: 0;
                    transform: translateY(-48%);
                }
                &:hover {
                    background-color: $theme-color;
                    color: #d7d7d7;
                    box-shadow: 0 10px 15px 0 rgba(0,0,0,0.4);
                }
            }
        }
    }
    h3 {
        margin-bottom: 25px;
        font-size: 26px;
    }
    .footer-links-list {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            margin-bottom: 12px;
            color: #d7d7d7;
            font-weight: 600;

            a {
                color: #d7d7d7;
                display: inline-block;

                &:hover {
                    color: $theme-color;
                    text-shadow: 0.2px 0.2px #d7d7d7;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .footer-contact-info {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            margin-bottom: 13px;
            color: #d7d7d7;
            position: relative;
            padding-left: 23px;
            font-weight: 600;

            a {
                color: #d7d7d7;
                display: inline-block;

                &:hover {
                    color: $theme-color;
                    text-shadow: 0.2px 0.2px #d7d7d7;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
            i {
                position: absolute;
                left: 0;
                color: #d7d7d7;
                top: 4px;

                &.bxs-inbox {
                    top: 3px;
                }
            }
        }
    }
}
.footer-bottom-area {
    margin-top: 70px;
    border-top: 1px solid #f3f2ee;
    padding: {
        top: 25px;
        bottom: 25px;
    };
    p {
        i {
            position: relative;
            top: 1.3px;
        }
        a {
            color: $theme-color;
            font-weight: 600;

            &:hover {
                color: #d7d7d7;
            }
        }
        strong {
            color: #d7d7d7;
        }
    }
    ul {
        text-align: right;
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            color: #d7d7d7;
            position: relative;
            display: inline-block;
            margin-right: 20px;

            &:last-child {
                margin-right: 0;

                &::before {
                    display: none;
                }
            }
            &::before {
                content: '';
                position: absolute;
                right: -12px;
                top: 5.6px;
                width: 1px;
                height: 14px;
                background-color: #d7d7d7;
            }
            a {
                display: block;
                color: #d7d7d7;

                &:hover {
                    color: #d7d7d7;
                }
            }
        }
    }
    @media #{$sm-layout} {
        text-align: center;
    }
}
