/*==========================
    Service Area
===========================*/
@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

.service{
  color: #ffffff;
    // Service Style One
    &.service__style--1 {
        padding: 40px 0;
        @extend %transition;
        @extend %posrelative;
        z-index: 2;
        @media #{$sm-layout} {
            display: none;
            padding: 15px 0;
        }
        @media #{$md-layout} {
            display: none;
            padding: 20px 0;
        }
        .icon {
            font-size: 72px;
            font-weight: 700;
            margin-bottom: 23px;
            display: inline-flex;
            color: $theme-color;
            @extend %transition;
            @media #{$md-layout} {
                margin-bottom: 11px;
            }
            @media #{$large-mobile} {
                color: #ffffff;
            }
           svg {
                stroke-width: 0.2 !important;
           }
        }
        .content {
            h4 {
                &.title {
                    color: #ffffff !important;
                    margin-bottom: 20px;
                    font-weight: 900;
                    font-size: 24px;
                    @media #{$sm-layout} {
                        margin-bottom: 12px;
                        font-weight: 400;
                        font-size: 20px;
                    }
                    @media #{$large-mobile} {
                        margin-bottom: 9px;
                        font-size: 18px;
                    }

                }
            }
            p {
                color: #ffffff !important;
                font-weight: 400;
            }
        }
    }
    &.service__style--3 {
        padding: 40px 0;
        @extend %transition;
        @extend %posrelative;
        z-index: 2;
        @media #{$sm-layout} {
            padding: 15px 0;
        }
        @media #{$md-layout} {
            padding: 20px 0;
        }
        .icon {
            font-size: 72px;
            font-weight: 700;
            margin-bottom: 23px;
            display: inline-flex;
            color: $theme-color;
            @extend %transition;
            @media #{$md-layout} {
                margin-bottom: 11px;
            }
           svg {
                stroke-width: 0.2 !important;
           }
        }
        .content {
            h4 {
                &.title {
                    margin-bottom: 20px;
                    font-weight: 500;
                    font-size: 24px;
                    @media #{$sm-layout} {
                        margin-bottom: 12px;
                        font-weight: 400;
                        font-size: 20px;
                    }
                    @media #{$large-mobile} {
                        margin-bottom: 9px;
                        font-size: 18px;
                    }

                }
            }
            p {
                color: rgba(29,29,36,0.75);
                font-weight: 400;
            }
        }
    }
    &.service__style--2 {
      background-image: linear-gradient(to right, rgba(14,67,107,1) , rgba(94,18,97,1));
      box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.9);
      filter: blur(2px);
      filter: drop-shadow(10px 10px 10px rgba(94,18,97,0.5));
        padding: 30px 35px;
        @extend %transition;
        @extend %posrelative;
        z-index: 2;
        border-radius: 10px;
        @media #{$laptop-device} {
            padding: 30px 28px;
        }
        @media #{$md-layout} {
            padding: 30px 25px;
        }
        @media #{$sm-layout} {
            padding: 30px 14px;
            filter: none;
        }
        @media #{$large-mobile} {
            padding: 30px 18px;
            margin-top: 30px;
        }

        &::before {
            content: "";
            left: 0;
            top: 0;
            // background-image: linear-gradient(to right, #ffffff , #fefefe);
            width: 100%;
            height: 100%;
            z-index: -1;
            opacity: 0;
            visibility: hidden;
            @extend %transition;
            border-radius: 10px;
            @media #{$large-mobile} {
                opacity: 0;
                visibility: hidden;
            }
        }
        .icon {
            font-size: 54px;
            font-weight: 400;
            margin-bottom: 23px;
            display: inline-flex;
            color: #ffffff;
            @extend %transition;
            @media #{$md-layout} {
                margin-bottom: 11px;
            }
            @media #{$large-mobile} {

            }
           svg {
                stroke-width: 1 !important;
           }
        }
        .content {
            h3 {
                &.title {
                    margin-bottom: 19px;
                    font-weight: 500;
                    color: #ffffff;
                    @extend %transition;
                    @media #{$laptop-device} {
                        font-size: 19px;
                    }
                    @media #{$sm-layout} {
                        font-size: 20px;
                    }
                    @media #{$md-layout} {
                        margin-bottom: 8px;
                    }
                    @media #{$large-mobile} {

                    }
                    a {
                        color: inherit;
                        @media #{$large-mobile} {
                            color: #ffffff;
                        }
                    }
                }
            }
            p {
                @extend %transition;
                color: #ffffff;
                @media #{$large-mobile} {

                }

            }
        }

        // &:hover{
        //     box-shadow: 0 10px 25px 10px rgba(0, 0, 0, 0.1);
        //     transform: translateY(-5px);
        //     &::before {
        //         opacity: 1;
        //         visibility: visible;
        //     }
        //     .icon{
        //         color: #201f26;
        //     }
        //     .content{
        //         h3 {
        //             &.title{
        //                 color: #201f26;
        //             }
        //         }
        //         p{
        //             color: #201f26;
        //         }
        //     }
        // }

        &.service-left-align {
            display: flex;
            padding: 50px 24px;
            .icon {
                padding-right: 20px;
            }
        }
    }
    &.tech-cards--1 {
      background-image: linear-gradient(to right, rgba(14,67,107,0.1) , #ebebeb);
      box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.3);
      filter: blur(2px);
      filter: drop-shadow(10px 10px 10px rgba(14,67,107,0.2));
        padding: 30px 35px;
        @extend %transition;
        @extend %posrelative;
        z-index: 2;
        border-radius: 10px;
        @media #{$laptop-device} {
            padding: 30px 28px;
        }
        @media #{$md-layout} {
            padding: 30px 25px;
        }
        @media #{$sm-layout} {
            padding: 30px 14px;
        }
        @media #{$large-mobile} {
            padding: 30px 18px;
            margin-top: 30px;
        }

        &::before {
            content: "";
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            opacity: 0;
            visibility: hidden;
            @extend %transition;
            border-radius: 10px;
            @media #{$large-mobile} {
                opacity: 0;
                visibility: hidden;
            }
        }
        .icon {
            font-size: 54px;
            font-weight: 400;
            margin-bottom: 23px;
            color: #ffffff;
            @extend %transition;
            @media #{$md-layout} {
                margin-bottom: 11px;
            }
            @media #{$large-mobile} {

            }
           svg {
                stroke-width: 1 !important;
           }
        }
        .content {
            h3 {
                &.title {
                    margin-bottom: 19px;
                    font-weight: 500;
                    color: #1d1d24;
                    @extend %transition;
                    @media #{$laptop-device} {
                        font-size: 19px;
                    }
                    @media #{$sm-layout} {
                        font-size: 20px;
                    }
                    @media #{$md-layout} {
                        margin-bottom: 8px;
                    }
                    @media #{$large-mobile} {

                    }
                    a {
                        color: inherit;
                        @media #{$large-mobile} {
                            color: #1d1d24;
                        }
                    }
                }
            }
            p {
                @extend %transition;
                color: #1d1d24;
                @media #{$large-mobile} {

                }

            }
        }

        // &:hover{
        //     box-shadow: 0 10px 25px 10px rgba(0, 0, 0, 0.1);
        //     transform: translateY(-5px);
        //     &::before {
        //         opacity: 1;
        //         visibility: visible;
        //     }
        //     .icon{
        //         color: #201f26;
        //     }
        //     .content{
        //         h3 {
        //             &.title{
        //                 color: #201f26;
        //             }
        //         }
        //         p{
        //             color: #201f26;
        //         }
        //     }
        // }

        &.service-left-align {
            display: flex;
            padding: 50px 24px;
            .icon {
                padding-right: 20px;
            }
        }
    }
    &.customsoftware__style--1 {
        padding: 30px 35px;
        @extend %transition;
        @extend %posrelative;
        z-index: 2;
        border-radius: 10px;
        @media #{$laptop-device} {
            padding: 30px 28px;
        }
        @media #{$md-layout} {
            padding: 30px 25px;
            -webkit-animation: heartbeat 4s ease-in-out infinite both;
              animation: heartbeat 4s ease-in-out infinite both;
        }
        @media #{$sm-layout} {
            padding: 30px 14px;
            -webkit-animation: heartbeat 4s ease-in-out infinite both;
              animation: heartbeat 4s ease-in-out infinite both;
        }
        @media #{$large-mobile} {
            padding: 30px 18px;
            margin-top: 30px;
            -webkit-animation: heartbeat 4s ease-in-out infinite both;
              animation: heartbeat 4s ease-in-out infinite both;
        }

        &::before {
            box-shadow: 10px 10px 25px 10px rgba(0, 0, 0, 0.9);
            filter: blur(10px);
            // filter: drop-shadow(10px 10px 10px 10px rgba(94,18,97,0.9));
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            // background-image: linear-gradient(to right, rgba(14,67,107,0.5) , rgba(94,18,97,0.5));
            width: 100%;
            height: 100%;
            z-index: -1;
            opacity: 1;
            visibility: visible;
            @extend %transition;
            border-radius: 10px;
            @media #{$large-mobile} {
              opacity: 1;
              visibility: visible;
            }
        }
        .icon {
            font-size: 54px;
            font-weight: 400;
            margin-bottom: 23px;
            display: inline-flex;
            color: #ffffff;
            @extend %transition;
            @media #{$md-layout} {
                margin-bottom: 11px;
            }
            @media #{$large-mobile} {

            }
           svg {
                stroke-width: 1 !important;
           }
        }
        .content {
            h3 {
                &.title {
                    margin-bottom: 19px;
                    font-weight: 500;
                    color: #ffffff;
                    @extend %transition;
                    @media #{$laptop-device} {
                        font-size: 19px;
                    }
                    @media #{$sm-layout} {
                        font-size: 20px;
                    }
                    @media #{$md-layout} {
                        margin-bottom: 8px;
                    }
                    @media #{$large-mobile} {

                    }
                    a {
                        color: inherit;
                        @media #{$large-mobile} {
                            color: #ffffff;
                        }
                    }
                }
            }
            p {
                @extend %transition;
                color: #ffffff;
                @media #{$large-mobile} {

                }

            }
        }

        &.service-left-align {
            display: flex;
            padding: 50px 24px;
            .icon {
                padding-right: 20px;
            }
        }
    }
    &.technology__style--1 {
        box-shadow: 0 10px 25px 10px rgba(0, 0, 0, 0.1);
        min-height: 500px;
        margin-bottom: 30px;
        padding: 30px 35px;
        @extend %transition;
        @extend %posrelative;
        z-index: 2;
        border-radius: 10px;
        @media #{$laptop-device} {
            padding: 30px 28px;
        }
        @media #{$md-layout} {
            padding: 30px 25px;
            min-height: 150px;
        }
        @media #{$sm-layout} {
            padding: 30px 14px;
        }
        @media #{$large-mobile} {
            padding: 30px 18px;
            margin-top: 30px;
        }

        &::before {
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            opacity: 0;
            visibility: hidden;
            @extend %transition;
            border-radius: 10px;
            @media #{$large-mobile} {
                opacity: 1;
                visibility: visible;
            }
        }
        .icon {
            width: auto;
            margin-bottom: 23px;
            @extend %transition;
            @media #{$md-layout} {
                margin-bottom: 11px;
            }
        }
        .content {
            h3 {
                &.title {
                    margin-bottom: 19px;
                    font-weight: 600;
                    @extend %transition;
                    @media #{$laptop-device} {
                        font-size: 19px;
                    }
                    @media #{$sm-layout} {
                        font-size: 20px;
                    }
                    @media #{$md-layout} {
                        margin-bottom: 8px;
                    }
                }
            }
            p {
                @extend %transition;
                text-align: left;


            }
        }

        &.service-left-align {
            display: flex;
            padding: 50px 24px;
            .icon {
                padding-right: 20px;
            }
        }

    }

}

/* ---------------------------
    Standard Service
-------------------------------*/
.standard-service {
    .thumbnai {
        img {
            border-radius: 5px;
        }
    }
    .content{
        h3 {
            font-size: 19px;
            margin-top: 30px;
            margin-bottom: 10px;
            font-weight: 600;
            a {
                @extend %transition;
                color: $heading-color;
                &:hover {
                    color: $theme-color;
                }
            }
        }
        p {
            font-size: 15px;
            line-height: 24px;
        }
        a {

        }
    }
}



.text-center ,
.text-left {
    .service {
        &.service__style--2 {
            padding: 60px 45px;
            @media #{$sm-layout} {
                padding: 30px 14px;
            }
            @media #{$lg-layout} {
                padding: 30px 14px;
            }
            @media #{$md-layout} {
                padding: 30px 14px;
            }
        }
    }
}

// Sercvice Style One
.service-white{
    // Service Style One
    .service__style--1 {
        z-index: 2;
        .content {
            h4 {
                &.title {
                   color: #c6c9d8;
                }
            }
            p{
                color: #c6c9d8;

            }
        }
    }
}

/*---------------------------
    Paralax Service Style
-----------------------------*/

.rn-paralax-service {
    .service__style--2 {
        z-index: 2;
        padding: 40px 40px;
        background: rgba(255,255,255, 0.3);
        margin-top: 40px;
        overflow: hidden;
        border: 2px solid transparent;

        @media #{$sm-layout} {
            padding: 30px 20px;
            background: transparent;
            margin-top: 30px;
        }

        .icon {
            color: #ffffff;
        }
        .content {
            h3 {
                &.title {
                    font-size: 19px;
                    color: #ffffff;
                }
            }
            p {
                color: rgba(255,255,255, 0.75);
            }
        }
        &:hover {
            border: 2px solid $theme-color;
            &::before {
                border-radius: 0;
            }
        }
    }
}

/* Creative Agency Service  */
.creative-service-wrapper {
    .row {
        &.creative-service {
            a{
                display: flex;
                height: 100%;
            }
            .service {

                &.service__style--2 {
                    margin-top: 30px;
                    background: #f6f6f6;
                }
            }
        }
    }

}



.service-one-wrapper{
  color: #ffffff;
    @media #{$md-layout} {
        margin: 0 -40px;
    }
}


/*================================================
About Area CSS
=================================================*/
.about-area {
    position: relative;
    z-index: 1;

    .container-fluid {
        max-width: 1920px;
    }
}
.our-mission-area {
    .container-fluid {
        max-width: 1920px;
    }
}
.about-content {
    .content {
        .sub-title {
            position: relative;
            display: block;
            text-transform: uppercase;
            margin-bottom: 15px;
            color: #ffffff;
            font: {
                weight: 700;
                size: 15px;
            };
            img {
                margin-right: 5px;
                animation: {
                    name: rotateme;
                    duration: 40s;
                    iteration-count: infinite;
                    timing-function: linear;
                };
            }
        }
        h2 {
            margin-bottom: 15px;
        }
        p {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }
        .features-list {
            padding-left: 0;
            list-style-type: none;
            display: block;
            margin: {
                bottom: 25px;
                top: -20px;
                left: -15px;
                right: -15px;
            };
            overflow: hidden;
            li {
                width: 45%;
                margin: {
                    left: 15px;
                    right: 15px;
                    top: 30px;
                };
                // float: left;

                display: block;
                border: 1px solid #f0eae2;
                border-radius: 5px;
                padding: 20px 20px 20px 95px;
                position: relative;
                background-color: #ffffff;
                transition: 0.1s;

                img {
                    position: absolute;
                    left: 20px;
                    top: 50%;
                    transform: translateY(-50%);
                }
                h3 {
                    margin-bottom: 3px;
                    font: {
                        size: 25px;
                    };
                }
                &:hover {
                    border-color: $theme-color;
                }
            }
        }
        .about-list {
            padding-left: 0;
            padding-bottom: 7px;
            list-style-type: none;
            overflow: hidden;
            margin: {
                bottom: 30px;
                top: -20px;
                left: -15px;
                right: -15px;
            };
            li {
                width: 275px;
                // float: left;
                margin: {
                    left: 15px;
                    right: 15px;
                };
                margin-top: 30px;
                position: relative;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
                padding: 20px 15px 20px 68px;
                border-radius: 5px;
                z-index: 1;
                background-color: RGBA(0, 0, 0, 0.8);
                border-color: $theme-color;
                border-width: 1px;
                border-style: solid;

                font: {
                    size: 19px;
                    weight: 800;
                };
                i {
                    position: absolute;
                    left: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                    border-radius: 3px;
                    width: 40px;
                    height: 40px;
                    line-height: 41px;
                    text-align: center;
                    transition: 0.5s;
                    background-color: $theme-color;
                    font-size: 20px;
                }
                img {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    z-index: -1;
                }
                &:hover {
                    i {
                        background-color: #ffffff;
                        color: $theme-color;
                    }
                }
            }
        }
        .content-list {
            padding-left: 0;
            list-style-type: none;
            margin: {
                bottom: 30px;
                top: 30px;
            };
            li {
                position: relative;
                margin-bottom: 35px;
                padding-left: 53px;
                font: {
                    size: 19px;
                    weight: 800;
                };
                i {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    border-radius: 3px;
                    width: 40px;
                    height: 40px;
                    line-height: 41px;
                    text-align: center;
                    transition: 2s;
                    background-color: #deecf4;
                    font-size: 20px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &:hover {
                    i {
                        background-color: #ffffff;
                        color: #ffffff;
                    }
                }
            }
        }
        .default-btn {
            margin-top: 5px;
        }
    }
}
.about-image {
    text-align: center;
}
.about-image-box {
    text-align: center;
    position: relative;
    margin-right: 20px;
    border-radius: 5px;
    padding: {
        bottom: 15px;
        right: 15px;
    };
    img {
        border-radius: 5px;
    }
    &::before {
        content: '';
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border-radius: 5px;
        position: absolute;
        background: #ffffff;
        z-index: -1;
        margin: {
            top: 15px;
            left: 15px;
        };
    }
    .box {
        background-color: #ffffff;
        left: 0;
        bottom: 15px;
        position: absolute;
        border-radius: 0 5px 0 0;
        padding: 10px 20px;
    }
    .video-btn {
        display: inline-block;
        text-transform: uppercase;
        color: #ffffff;
        font: {
            size: 16px;
            weight: 700;
        };
        i {
            width: 55px;
            height: 55px;
            line-height: 56px;
            box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .10);
            display: inline-block;
            text-align: center;
            border-radius: 50%;
            background-color: #ffffff;
            font-size: 20px;
            margin-right: 5px;
            transition: 2s;
        }
        &:hover {
            i {
                background-color: #ffffff;
                color: #ffffff;
            }
        }
    }
}
.about-img {
    text-align: center;
    position: relative;
    z-index: 1;

}
.about-inner-area {
    margin-top: 100px;
}
.about-text {
    h3 {
        margin-bottom: 13px;
        font: {
            size: 25px;
        }
    }
    .features-list {
        padding-left: 0;
        list-style-type: none;
        margin: {
            top: 15px;
            bottom: 0;
        }
        li {
            position: relative;
            margin-bottom: 12px;
            color: $theme-color;
            font-weight: 600;
            padding: {
                top: 5px;
                left: 30px;
            };
            i {
                font-size: 9px;
                color: #000000;
                background-color: #eaeaf2;;
                width: 20px;
                height: 20px;
                line-height: 20px;
                border-radius: 50%;
                transition: 0.5s;
                text-align: center;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
            &:last-child {
                margin-bottom: 0;
            }
            &:hover {
                i {
                    background-color: $theme-color;
                    color: #ffffff;
                }
            }
        }
    }
}
.circle-shape1 {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
}
@keyframes rotateme {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.software-product-lifecycle {
  margin-top: 500px;
  overflow: hidden;
  @media #{$sm-layout} {
    margin-top: 200px;

  }
}

.custom-software-services {
  // width: 100%;
  margin-top: 500px;
  overflow: hidden;
  @media #{$sm-layout} {
    padding-top:40px;
  }

.custom-software-services-card {
  height: auto;
  overflow: hidden;
  width: 400px;
  padding-top: 40px;
  text-align: center;
  // background: #52C0F5;
  // color: #FFF;
  border-radius: 10px;
  box-sizing: border-box;
  @media #{$sm-layout} {
      width: 83vw;
  }
}
}
.custom-software-services-arrow {
  position: absolute;
  top: 40%;
  right: 95%;
  @media #{$sm-layout} {
      display: none;
  }
}

.tech-services {
  // width: 100%;
  overflow: hidden;
  @media #{$sm-layout} {
    padding-top: 100px;
    margin-bottom: 600px;
  }
}

.tech-cards {
  height: auto;
  overflow: hidden;
  width: 400px;
  padding-top: 100px;
  text-align: left;
  background: #ebebeb;
  border-radius: 10px;
  box-sizing: border-box;
  h3 {
    text-align: center;
  }
  @media #{$sm-layout} {
      width: 84vw;
      height: auto;
      img {
        height: 80px;
        width: auto;
      }
  }
  img {
    align-items: center;
  }
}

.tech-arrow {
  position: relative;
}

.tech_button {
  padding-top: 250px;
    @media #{$sm-layout} {
      padding-top: 0px;
    }

}

.tech-card {
  margin-top: 200px;
  height: auto;
  overflow:visible;
  width: 40vw;
  height: 200px;
  vertical-align: middle;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  box-sizing: border-box;
  img {
    height: 150px;
  }
  @media #{$sm-layout} {
      // margin-top: 300px;
      display: none;
      width: 200px;
      img {
        max-width: 100px;
        height: auto;
      }
  }
}
