/*====================
Mainmenu Area
=====================*/

.mainmenunav {
  ul {
    &.mainmenu {
      font-family: $font-1;
      display: flex;
      justify-content: flex-end;
      @extend %liststyle;
      flex-wrap: wrap;
      > li {
        margin: 0 15px;
        position: relative;
        > a {
          @extend %transition;
          color: #ffffff;
          font-size: 18px;
          font-weight: 700;
          padding: 20px 0;
          display: inline-block;
        }
        > ul {
          &.submenu {
            @extend %liststyle;
            min-width: 375px;
            height: auto;
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 90;
            opacity: 0.5;
            visibility: hidden;
            background-color: #fff;
            box-shadow: 0 0 35px rgba(0,0,0,0.1);
            text-align: left;
            @extend %transition;
            padding: 12px 0;
            border-radius: 4px;
            li {
              a {
                font-size: 16px;
                font-weight: 500;
                padding: 5px 20px;
                font-size: 16px;
                display: block;
                color: #1f1f25;
                margin: 0 10px;
                border-radius: 3px;
                @extend %transition;
              }
              &:hover {
                a {
                  color: #ffffff;
                  font-weight: 700;
                  background-image: linear-gradient(to right, $theme-color , #5cb8e2);
                }
              }
            }
          }
        }
        &:hover {
          > ul {
            &.submenu {
              opacity: 1;
              visibility: visible;
            }
          }
        }

        &:hover {
          > a {
            color: $theme-color;
          }
        }
        &:first-child{
          margin-left: 0;
        }
        &:last-child{
          margin-right: 0;
        }
      }
    }
  }
}

.humberger-menu{
  span{
    &.text-white{
      color: #c6c9d8 !important;

    }
  }
}

.color-white{
  .mainmenunav {
    ul {
      &.mainmenu {
        @extend %liststyle;
        > li {
          > a {
            color: #ffffff;
          }
          &:hover {
            > a {
              color: rgba(255,255,255,0.6);
              @media #{$md-layout} {
                color: $theme-color;
              }
              @media #{$sm-layout} {
                color: $theme-color;
              }
            }
          }
        }
      }
    }
  }
}

.color-black{
  .mainmenunav {
    ul {
      &.mainmenu {
        @extend %liststyle;
        > li {
          > a {
            color: rgba(29, 29, 36, 1);
          }
          &:hover {
            > a {
              color: $theme-color;
            }
          }
        }
      }
    }
  }
}


@media #{$lg-layout}{
  .header-style-two .humberger-menu span.text-white {
    color: #1d1d24 !important;
  }
}

@media #{$md-layout}{
  .header-style-two .humberger-menu span.text-white{
    color: #1d1d24 !important;
  }
}

@media #{$sm-layout}{
  .header-style-two .humberger-menu span.text-white{
    color: #1d1d24 !important;
  }
}

#fp-nav {
  &.fp-right {
    right: -15px !important;
    @media (max-width: 1199px) {
      right: -15px !important;
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
  ul {
    list-style: none;
    li {
      width: auto !important;
      height: auto !important;
      a {
        display: block;
        span {
          background: rgba(247, 228, 228, 0.3) !important;
          transition: all 0.2s linear;
          left: 35%;
          &.fp-sr-only {
            position: relative;
            background: transparent !important;
            width: auto;
            height: auto;
            margin-left: 0 !important;
            left: 0px !important;
            opacity: 0;
            transition: left 0.3s linear, opacity 0.2s;
          }
        }
        &.active,
        &:hover {
          span {
            -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
            animation: heartbeat 1.5s ease-in-out infinite both;
            background: rgba(255, 255, 255, 0.8) !important;
            &.fp-sr-only {
              left: 0px !important;
              opacity: 1;
              width: auto;
              height: auto;
              opacity: 0;
              transition: left 0.3s linear, opacity 0.6s;
              background: transparent !important;
            }
          }
        }
      }
    }
  }
}

.home-menu {
  ul {
    &.mainmenu {
      > a {
        color: #ffffff;
      }
      &:hover {
        > a {
          color: $theme-color;
        }
      }
      > li {
        > a {
          color: #ffffff;
        }
        &:hover {
          > a {
            color: $theme-color;
          }
        }
        ul {
          > li {
            > a {
              color: #ffffff;
            }
            &:hover {
              > a {
                color: $theme-color;
              }
            }
          }
        }
      }
    }
  }
}
