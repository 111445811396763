/* ----------------------------------------------
 * Generated by Animista on 2020-9-17 7:52:22
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation pulsate-bck
 * ----------------------------------------
 */
@-webkit-keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}




ul{
    &.tab-style--1 {
        @extend %liststyle;
        display: flex;
        flex-direction: column;
        margin: 0 -20px;
        flex-wrap: wrap;
        color: #ffffff;
        @media #{$lg-layout} {
            margin: 0 -10px;
        }


        li {
            position: relative;
            margin: 0 20px;
            display: inline-block;
            padding-bottom: 4px;
            font-size: 24px;
            font-weight: 500;
            cursor: pointer;

            @media #{$lg-layout} {
                margin: 0 10px;
                font-size: 17px;
            }

            @media #{$sm-layout} {
                margin-bottom: 10px;
                font-size: 16px;
            }

            &::before {
                position: absolute;
                content: "";
                background: rgba(0, 0, 0, 0.0);
                transition: all 0.7s cubic-bezier(0.19, 1, 0.22, 1) 0s;
                bottom: 0;

            }
            &.react-tabs__tab--selected{
              color: #ffffff;
              width: 80%;
              -webkit-animation: pulsate-bck 1.5s ease-in-out infinite both;
            	        animation: pulsate-bck 1.5s ease-in-out infinite both;
                background-image: url('/assets/images/bg/main-bg.svg');
                box-shadow: 10px 10px 25px 10px rgba(0, 0, 0, 1);
                background-repeat: no-repeat;
                background-size: 100% auto;
                border-radius: 12px;
                overflow: hidden;
                object-fit: fill;
                font-weight: 700;
                padding: 5px;
                &::before{
                    width: 100%;
                    background: currentColor;
                }
            }
            svg {
              font-size: 40px;
              margin: 10px;
              padding: 5px;
              @media #{$sm-layout} {
                  font-size: 25px;
              }
            }
        }
    }
    &.tab-style--2 {
        @extend %liststyle;
        display: flex;
        margin: 0 -20px;
        flex-wrap: wrap;

        @media #{$lg-layout} {
            margin: 0 -10px;
        }


        li {
            margin-top: 10px;
            position: relative;
            margin: 0 20px;
            display: inline-block;
            padding-bottom: 4px;
            font-size: 20px;
            font-weight: 500;
            cursor: pointer;
            outline: none;

            @media #{$lg-layout} {
                margin: 0 10px;
                font-size: 17px;
            }

            @media #{$sm-layout} {
                margin-bottom: 10px;
            }

            &::before {
                position: absolute;
                content: "";
                width: 30px;
                background: rgba(0, 0, 0, 0.2);
                transition: all 0.7s cubic-bezier(0.19, 1, 0.22, 1) 0s;
                height: 2px;
                bottom: 0;

            }
            &.react-tabs__tab--selected{
                color: $theme-color;
                &::before{
                    width: 100%;
                    background: currentColor;
                }
            }
        }
    }

}

.single-tab-content {
  color: #ffffff;
  border-radius: 10px;
  margin-top: 10px;
    padding: 10px;

    p {
        font-weight: 500;
        font-size: 20px;
        line-height: 35px;
    }
}

.terms-tab-content {
  height: 100%;
  box-shadow: 0 10px 25px 10px rgba(0, 0, 0, 0.0);
  border-radius: 10px;
  margin-top: 10px;
    padding: 20px;

    p {
        font-weight: 500;
        font-size: 20px;
        line-height: 35px;
    }
}

.tab-background {
  background-color: rgba(29,29,36,0.0);
  border-radius: 5px;
  padding: 20px;
}

.single-tab-content{
    ul{
        &.list-style--1{
            li{
                margin-bottom: 10px;
            }
        }
        padding: 0;
        margin: 0;
        list-style: none;
        li{
            color: rgba(29,29,36,0.75);
            font-size: 18px;
            line-height: inherit;
            margin-bottom: 15px;
            font-weight: 300;
            @media #{$sm-layout} {
                margin-bottom: 10px;
                font-size: 14px;
            }
            a{
                font-weight: 500;
                display: block;
                color: rgba(29,29,36,0.75);
                span{
                    font-weight: 300;
                }
            }
        }
    }
}
