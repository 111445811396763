/*==========================
    Custom Software Area
===========================*/
.customsoftware_list {
    &.custom_software__style--1 {
        box-shadow: 0 10px 25px 10px rgba(0, 0, 0, 0.1);
        padding: 30px 35px;
        margin: 10px;
        max-width: 80%;
        background-color: #ffffff;
        @extend %transition;
        @extend %posrelative;
        z-index: 2;
        border-radius: 10px;
        @media #{$laptop-device} {
            padding: 30px 28px;
        }
        @media #{$md-layout} {
            padding: 30px 25px;
        }
        @media #{$sm-layout} {
            padding: 30px 14px;
        }
        @media #{$large-mobile} {
            padding: 30px 18px;
            margin-top: 30px;
            margin: 10px 5px 10px 5px;
            max-width: 100%;
        }

        &::before {
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            background-image: linear-gradient(to right, $theme-color , #5cb8e2);
            width: 100%;
            height: 100%;
            z-index: -1;
            opacity: 0;
            visibility: hidden;
            @extend %transition;
            border-radius: 10px;
        }
        .icon {
            font-size: 54px;
            font-weight: 400;
            margin-bottom: 23px;
            display: inline-flex;
            color: $theme-color;
            @extend %transition;
            @media #{$md-layout} {
                margin-bottom: 11px;
            }
           svg {
                stroke-width: 1 !important;
           }
        }
        .content {
            h3 {
                &.title {
                    margin-bottom: 19px;
                    font-weight: 500;
                    @extend %transition;
                    @media #{$laptop-device} {
                        font-size: 19px;
                    }
                    @media #{$sm-layout} {
                        font-size: 20px;
                    }
                    @media #{$md-layout} {
                        margin-bottom: 8px;
                    }
                    a {
                        color: inherit;
                    }
                }
            }
            p {
                @extend %transition;
                color: rgba(29,29,36,0.75);

            }
        }

        &:hover{
            box-shadow: 0 10px 25px 10px rgba(0, 0, 0, 0.1);
            transform: translateY(-5px);
            &::before {
                opacity: 1;
                visibility: visible;
            }
            .icon{
                color: #ffffff;
            }
            .content{
                h3 {
                    &.title{
                        @extend %textwhite;
                    }
                }
                p{
                    @extend %textwhite;
                }
            }
        }

        &.service-left-align {
            display: flex;
            padding: 50px 24px;
            .icon {
                padding-right: 20px;
            }
        }
    }
  }

.customsoftware {
    &.custom_software__style--1 {
        box-shadow: 0 10px 25px 10px rgba(0, 0, 0, 0.1);
        padding: 30px 35px;
        margin: 20px;
        @extend %transition;
        @extend %posrelative;
        z-index: 2;
        border-radius: 10px;
        @media #{$laptop-device} {
            padding: 30px 28px;
        }
        @media #{$md-layout} {
            padding: 30px 25px;
        }
        @media #{$sm-layout} {
            padding: 30px 14px;
        }
        @media #{$large-mobile} {
            padding: 30px 18px;
            margin-top: 30px;
        }

        &::before {
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            background-image: linear-gradient(to right, $theme-color , #5cb8e2);
            width: 100%;
            height: 100%;
            z-index: -1;
            opacity: 0;
            visibility: hidden;
            @extend %transition;
            border-radius: 10px;
            @media #{$large-mobile} {
                opacity: 0;
                visibility: hidden;
            }
        }
        .icon {
            font-size: 54px;
            font-weight: 400;
            margin-bottom: 23px;
            display: inline-flex;
            color: $theme-color;
            @extend %transition;
            @media #{$md-layout} {
                margin-bottom: 11px;
            }
           svg {
                stroke-width: 1 !important;
           }
        }
        .content {
            h3 {
                &.title {
                    margin-bottom: 19px;
                    font-weight: 500;
                    @extend %transition;
                    @media #{$laptop-device} {
                        font-size: 19px;
                    }
                    @media #{$sm-layout} {
                        font-size: 20px;
                    }
                    @media #{$md-layout} {
                        margin-bottom: 8px;
                    }
                    @media #{$large-mobile} {
                        color: #ffffff;
                    }
                    a {
                        color: inherit;
                        @media #{$large-mobile} {
                            color: #ffffff;
                        }
                    }
                }
            }
            p {
                @extend %transition;
                color: rgba(29,29,36,0.75);
                @media #{$large-mobile} {
                    color: #ffffff;
                }

            }
        }

        &:hover{
            box-shadow: 0 10px 25px 10px rgba(0, 0, 0, 0.1);
            transform: translateY(-5px);
            &::before {
                opacity: 1;
                visibility: visible;
            }
            .icon{
                color: #ffffff;
            }
            .content{
                h3 {
                    &.title{
                        @extend %textwhite;
                    }
                }
                p{
                    @extend %textwhite;
                }
            }
        }

        &.service-left-align {
            display: flex;
            padding: 50px 24px;
            .icon {
                padding-right: 20px;
            }
        }
    }

}

.softwareproduct {
    background-image: url(/assets/images/softwareproduct/wave.svg);
    background-repeat: repeat;
    padding-bottom: -100px;
    &.softwareproduct__style--1 {
        box-shadow: 0 10px 25px 10px rgba(0, 0, 0, 0.1);
        padding: 30px 35px;
        margin: 20px;
        @extend %transition;
        @extend %posrelative;
        z-index: 2;
        border-radius: 10px;
        @media #{$laptop-device} {
            padding: 30px 28px;
        }
        @media #{$md-layout} {
            padding: 30px 25px;
        }
        @media #{$sm-layout} {
            padding: 30px 14px;
        }
        @media #{$large-mobile} {
            padding: 30px 18px;
            margin-top: 30px;
        }

        &::before {
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            background-image: linear-gradient(to right, $theme-color , #5cb8e2);
            width: 100%;
            height: 100%;
            z-index: -1;
            opacity: 0;
            visibility: hidden;
            @extend %transition;
            border-radius: 10px;
            @media #{$large-mobile} {
                opacity: 0;
                visibility: hidden;
            }
        }
        .icon {
            font-size: 54px;
            font-weight: 400;
            margin-bottom: 23px;
            display: inline-flex;
            color: $theme-color;
            @extend %transition;
            @media #{$md-layout} {
                margin-bottom: 11px;
            }
           svg {
                stroke-width: 1 !important;
           }
        }
        .content {
            h3 {
                &.title {
                    margin-bottom: 19px;
                    font-weight: 500;
                    @extend %transition;
                    @media #{$laptop-device} {
                        font-size: 19px;
                    }
                    @media #{$sm-layout} {
                        font-size: 20px;
                    }
                    @media #{$md-layout} {
                        margin-bottom: 8px;
                    }
                    @media #{$large-mobile} {
                        color: #ffffff;
                    }
                    a {
                        color: inherit;
                        @media #{$large-mobile} {
                            color: #ffffff;
                        }
                    }
                }
            }
            p {
                @extend %transition;
                color: rgba(29,29,36,0.75);
                @media #{$large-mobile} {
                    color: #ffffff;
                }

            }
        }

        &:hover{
            box-shadow: 0 10px 25px 10px rgba(0, 0, 0, 0.1);
            transform: translateY(-5px);
            &::before {
                opacity: 1;
                visibility: visible;
            }
            .icon{
                color: #ffffff;
            }
            .content{
                h3 {
                    &.title{
                        @extend %textwhite;
                    }
                }
                p{
                    @extend %textwhite;
                }
            }
        }

        &.service-left-align {
            display: flex;
            padding: 50px 24px;
            .icon {
                padding-right: 20px;
            }
        }
    }

}

.datacollection {
    background-image: url(/assets/images/DataCollection/wave.svg);
    background-repeat: repeat;
    padding-bottom: -100px;
    &.custom_software__style--1 {
        box-shadow: 0 10px 25px 10px rgba(0, 0, 0, 0.1);
        padding: 30px 35px;
        margin: 20px;
        @extend %transition;
        @extend %posrelative;
        z-index: 2;
        border-radius: 10px;
        @media #{$laptop-device} {
            padding: 30px 28px;
        }
        @media #{$md-layout} {
            padding: 30px 25px;
        }
        @media #{$sm-layout} {
            padding: 30px 14px;
        }
        @media #{$large-mobile} {
            padding: 30px 18px;
            margin-top: 30px;
        }

        &::before {
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            background-image: linear-gradient(to right, $theme-color , #5cb8e2);
            width: 100%;
            height: 100%;
            z-index: -1;
            opacity: 0;
            visibility: hidden;
            @extend %transition;
            border-radius: 10px;
            @media #{$large-mobile} {
                opacity: 0;
                visibility: hidden;
            }
        }
        .icon {
            font-size: 54px;
            font-weight: 400;
            margin-bottom: 23px;
            display: inline-flex;
            color: $theme-color;
            @extend %transition;
            @media #{$md-layout} {
                margin-bottom: 11px;
            }
           svg {
                stroke-width: 1 !important;
           }
        }
        .content {
            h3 {
                &.title {
                    margin-bottom: 19px;
                    font-weight: 500;
                    @extend %transition;
                    @media #{$laptop-device} {
                        font-size: 19px;
                    }
                    @media #{$sm-layout} {
                        font-size: 20px;
                    }
                    @media #{$md-layout} {
                        margin-bottom: 8px;
                    }
                    @media #{$large-mobile} {
                        color: #ffffff;
                    }
                    a {
                        color: inherit;
                        @media #{$large-mobile} {
                            color: #ffffff;
                        }
                    }
                }
            }
            p {
                @extend %transition;
                color: rgba(29,29,36,0.75);
                @media #{$large-mobile} {
                    color: #ffffff;
                }

            }
        }

        &:hover{
            box-shadow: 0 10px 25px 10px rgba(0, 0, 0, 0.1);
            transform: translateY(-5px);
            &::before {
                opacity: 1;
                visibility: visible;
            }
            .icon{
                color: #ffffff;
            }
            .content{
                h3 {
                    &.title{
                        @extend %textwhite;
                    }
                }
                p{
                    @extend %textwhite;
                }
            }
        }

        &.service-left-align {
            display: flex;
            padding: 50px 24px;
            .icon {
                padding-right: 20px;
            }
        }
    }

}

.prototyping {
    background-image: url(/assets/images/Prototyping/wave.svg);
    background-repeat: no-repeat;
    padding-bottom: -100px;
    &.custom_software__style--1 {
        box-shadow: 0 10px 25px 10px rgba(0, 0, 0, 0.1);
        padding: 30px 35px;
        margin: 20px;
        @extend %transition;
        @extend %posrelative;
        z-index: 2;
        border-radius: 10px;
        @media #{$laptop-device} {
            padding: 30px 28px;
        }
        @media #{$md-layout} {
            padding: 30px 25px;
        }
        @media #{$sm-layout} {
            padding: 30px 14px;
        }
        @media #{$large-mobile} {
            padding: 30px 18px;
            margin-top: 30px;
        }

        &::before {
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            background-image: linear-gradient(to right, $theme-color , #5cb8e2);
            width: 100%;
            height: 100%;
            z-index: -1;
            opacity: 0;
            visibility: hidden;
            @extend %transition;
            border-radius: 10px;
            @media #{$large-mobile} {
                opacity: 0;
                visibility: hidden;
            }
        }
        .icon {
            font-size: 54px;
            font-weight: 400;
            margin-bottom: 23px;
            display: inline-flex;
            color: $theme-color;
            @extend %transition;
            @media #{$md-layout} {
                margin-bottom: 11px;
            }
           svg {
                stroke-width: 1 !important;
           }
        }
        .content {
            h3 {
                &.title {
                    margin-bottom: 19px;
                    font-weight: 500;
                    @extend %transition;
                    @media #{$laptop-device} {
                        font-size: 19px;
                    }
                    @media #{$sm-layout} {
                        font-size: 20px;
                    }
                    @media #{$md-layout} {
                        margin-bottom: 8px;
                    }
                    @media #{$large-mobile} {
                        color: #ffffff;
                    }
                    a {
                        color: inherit;
                        @media #{$large-mobile} {
                            color: #ffffff;
                        }
                    }
                }
            }
            p {
                @extend %transition;
                color: rgba(29,29,36,0.75);
                @media #{$large-mobile} {
                    color: #ffffff;
                }

            }
        }

        &:hover{
            box-shadow: 0 10px 25px 10px rgba(0, 0, 0, 0.1);
            transform: translateY(-5px);
            &::before {
                opacity: 1;
                visibility: visible;
            }
            .icon{
                color: #ffffff;
            }
            .content{
                h3 {
                    &.title{
                        @extend %textwhite;
                    }
                }
                p{
                    @extend %textwhite;
                }
            }
        }

        &.service-left-align {
            display: flex;
            padding: 50px 24px;
            .icon {
                padding-right: 20px;
            }
        }
    }

}

.software {
    &.custom_software__style--1 {
        box-shadow: 0 10px 25px 10px rgba(0, 0, 0, 0.1);
        padding: 30px 35px;
        margin: 20px;
        background-color: #ffffff;
        @extend %transition;
        @extend %posrelative;
        z-index: 2;
        border-radius: 10px;
        @media #{$laptop-device} {
            padding: 30px 28px;
        }
        @media #{$md-layout} {
            padding: 30px 25px;
            box-shadow: none;
            background-color: rgba(0, 0, 0, 0.0);
        }
        @media #{$sm-layout} {
            padding: 30px 14px;
        }
        @media #{$large-mobile} {
            padding: 30px 18px;
            margin-top: 30px;
        }
}
&.custom_software__style--2 {
    padding: 30px 35px;
    margin: 20px;
    background-repeat: no-repeat;
    background-size: 700px 700px;
    background-color: #ffffff;
    @extend %transition;
    @extend %posrelative;
    z-index: 2;
    border-radius: 10px;
    @media #{$laptop-device} {
        padding: 30px 28px;
        margin: 5px 5px 5px 5px;
    }
    @media #{$md-layout} {
        padding: 30px 25px;
        margin: 5px 5px 5px 5px;
    }
    @media #{$sm-layout} {
        padding: 30px 14px;
        margin: 5px 5px 5px 5px;
    }
    @media #{$large-mobile} {
        padding: 30px 18px;
        margin: 5px 5px 5px 5px;

    }
}

}

/* ---------------------------
    Standard Service
-------------------------------*/
.standard-service {
    .thumbnai {
        img {
            border-radius: 5px;
        }
    }
    .content{
        h3 {
            font-size: 19px;
            margin-top: 30px;
            margin-bottom: 10px;
            font-weight: 600;
            a {
                @extend %transition;
                color: $heading-color;
                &:hover {
                    color: $theme-color;
                }
            }
        }
        p {
            font-size: 15px;
            line-height: 24px;
        }
        a {

        }
    }
}



.text-center ,
.text-left {
    .service {
        &.service__style--2 {
            padding: 60px 45px;
            @media #{$sm-layout} {
                padding: 30px 14px;
            }
            @media #{$lg-layout} {
                padding: 30px 14px;
            }
            @media #{$md-layout} {
                padding: 30px 14px;
            }
        }
    }
}

// Sercvice Style One
.service-white{
    // Service Style One
    .service__style--1 {
        z-index: 2;
        .content {
            h4 {
                &.title {
                   color: #c6c9d8;
                }
            }
            p{
                color: #c6c9d8;

            }
        }
    }
}

/*---------------------------
    Paralax Service Style
-----------------------------*/

.rn-paralax-service {
    .service__style--2 {
        z-index: 2;
        padding: 40px 40px;
        background: rgba(255,255,255, 0.3);
        margin-top: 40px;
        overflow: hidden;
        border: 2px solid transparent;

        @media #{$sm-layout} {
            padding: 30px 20px;
            background: transparent;
            margin-top: 30px;
        }

        .icon {
            color: #ffffff;
        }
        .content {
            h3 {
                &.title {
                    font-size: 19px;
                    color: #ffffff;
                }
            }
            p {
                color: rgba(255,255,255, 0.75);
            }
        }
        &:hover {
            border: 2px solid $theme-color;
            &::before {
                border-radius: 0;
            }
        }
    }
}

/* Creative Agency Service  */
.creative-service-wrapper {
    .row {
        &.creative-service {
            a{
                display: flex;
                height: 100%;
            }
            .service {

                &.service__style--2 {
                    margin-top: 30px;
                    background: #f6f6f6;
                }
            }
        }
    }

}



.service-one-wrapper{
    @media #{$md-layout} {
        margin: 0 -40px;
    }
}
