.animus-footer-menu {
  // mix-blend-mode: difference;
  z-index: 1;
  font-family: NHaasGroteskDSPro-65Md,"Helvetica Neue",Helvetica,Arial,sans-serif;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  color: #ffffff;
  padding: 20px;
  pointer-events: none;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: row;
  font-size: 12px;
  line-height: 1.5em;

.brand span {
  font-family: 'Beckman', sans-serif;
  font-weight: 900;
}

.description span {
  font-family: NHaasGroteskDSPro-65Md,"Helvetica Neue",Helvetica,Arial,sans-serif;
}

.date {
  font-family: NHaasGroteskDSPro-65Md,"Helvetica Neue",Helvetica,Arial,sans-serif;
}

a {
  font-family: NHaasGroteskDSPro-65Md,"Helvetica Neue",Helvetica,Arial,sans-serif;
}

}

.animus-footer-menu > div {
  word-wrap: none;
  word-break: none;
  white-space: pre;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.animus-footer-menu > div b {
  color: #ffffff;
}

.animus-footer-menu a {

  pointer-events: all;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

.animus-footer-menu a:hover {
  text-decoration: underline;
  color: inherit;
}

.rotate {

  transform: rotate(-90deg);


  /* Legacy vendor prefixes that you probably don't need... */

  /* Safari */
  -webkit-transform: rotate(-90deg);

  /* Firefox */
  -moz-transform: rotate(-90deg);

  /* IE */
  -ms-transform: rotate(-90deg);

  /* Opera */
  -o-transform: rotate(-90deg);

  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);

}

.footer-container {

}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.footer-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #008CBA;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .5s ease;
}

.footer-container:hover .footer-overlay {
  height: 100%;
  z-index: 9999999;
}

.footer-text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
