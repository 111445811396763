/*====================
    About Area
======================*/

.rn-finding-us-area {
    .inner {
        position: relative;
        padding-left: 240px;
        padding-top: 110px;
        padding-bottom: 110px;

        @media #{$lg-layout} {
            padding-left: 0;
            padding-top: 0;
            padding-bottom: 0;
            display: flex;
            flex-direction: column-reverse;
        }

        @media #{$md-layout} {
            padding-left: 0;
            padding-top: 0;
            padding-bottom: 0;
            display: flex;
            flex-direction: column-reverse;
        }

        @media #{$sm-layout} {
            padding-left: 0;
            padding-top: 0;
            padding-bottom: 0;
            display: flex;
            flex-direction: column-reverse;
        }
        .content-wrapper {
          .content {
              background: #272527;
              padding: 120px 100px;
              position: relative;
              z-index: 2;
              width: 800px;
              border-radius: 10px;

                @media #{$lg-layout} {
                    padding: 60px 50px;
                    width: 100%;
                    border-radius: 0;
                }

                @media #{$md-layout} {
                    padding: 60px 50px;
                    width: 100%;
                    border-radius: 0;
                }

                @media #{$sm-layout} {
                    padding: 30px 30px;
                    width: 100%;
                    border-radius: 0;
                }

                h4 {
                    color: #ffffff;
                    font-size: 36px;
                    font-weight: 500;
                    margin-bottom: 20px;
                    @media #{$sm-layout} {
                        font-size: 26px;
                        margin-bottom: 12px;
                    }
                }
                p {
                    font-size: 18px;
                    color: #ffffff;
                    font-weight: 300;
                    margin-bottom: 41px;
                    padding-right: 10%;
                    @media #{$sm-layout} {
                        margin-bottom: 24px;
                        padding-right: 0;
                    }
                }
            }
        }
        .thumbnail{
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            @media #{$lg-layout} {
                position: static;
                height: 100%;
            }

            @media #{$md-layout} {
                position: static;
                height: 100%;
            }

            @media #{$sm-layout} {
                position: static;
                height: 100%;
            }
            img {
              border-radius: 10px;
                @media #{$lg-layout} {
                    border-radius: 0;
                }
                @media #{$md-layout} {
                    border-radius: 0;
                }
                @media #{$sm-layout} {
                    border-radius: 0;
                }
            }
        }
        .thumbnailtwo{
            position: relative;
            min-height: 100%;
            height: 100%;
            object-fit: cover;
            img {
                border-radius: 10px;
                @media #{$lg-layout} {
                    border-radius: 0;
                }
                @media #{$md-layout} {
                    border-radius: 0;
                }
                @media #{$sm-layout} {
                    border-radius: 0;
                }
            }
        }
    }
}

.about-inner {
    @media #{$md-layout} {
        padding-top: 0px;
    }
    @media #{$sm-layout} {
        padding-top: 0px;
    }
    .section-title{
        h2{
            &.title{
                margin-bottom: 8px;
            }
            @media #{$md-layout} {
                margin-top: -80px !important;
                font-size: 70px;
            }
            @media #{$sm-layout} {
                margin-top: -80px !important;
                font-size: 70px;
            }
        }
        p{
            &.description{
                font-weight: 400;
                color: rgba(29,29,36,0.75);
                a{
                    color: rgba(29, 29, 36, 1);
                    &:hover{
                        color: $theme-color;
                    }
                }
            }
        }

    }
}

.about-wrapper {
    .thumbnail {
        position: relative;
        z-index: 2;
        padding: 20px;
        img{
            border-radius: 0px;
            box-shadow: none !important;
            @media #{$sm-layout} {
                display: none;
            }
            @media #{$md-layout} {
                display: none;
            }
        }
    }
    .thumbnailtwo {
        position: relative;
        z-index: 2;
        align-items: center;
        img{
          box-shadow: rgb(237, 241, 254) 100px 100px 100px 70px;
            @media #{$md-layout} {
                display: none;
            }
            @media #{$sm-layout} {
                display: none;
            }
        }
    }
}

.about-position-top {
    @media #{$lg-layout} {
        padding-top: 120px;
    }
    @media #{$md-layout} {
        padding-top: 80px;
    }
    @media #{$sm-layout} {
        padding-top: 60px;
    }
    .thumbnail {
        margin-top: -130px;
        @media #{$lg-layout} {
            margin-top: 0;
        }
        @media #{$laptop-device} {
            margin-top: -89px;
        }
        @media #{$md-layout} {
            margin-top: 0;
        }
        @media #{$sm-layout} {
            margin-top: 0;
        }
    }
}

.about-us-list{
    margin-top: 15px;
    h3{
        &.title{
            font-size: 30px;
            @extend %fontWeight500;
            margin-bottom: 20px;
            @media #{$sm-layout} {
                font-size: 26px;
                margin-bottom: 8px;
            }
        }
    }
    p{
        font-weight: 400;
        color: rgba(29,29,36,0.75);
    }
}

.rn-content-box-style--1 {
    .content{
        text-align: left;
        padding: 0 120px;
        @media #{$md-layout} {
            padding: 0 60px;
        }
        @media #{$sm-layout} {
            padding: 0 40px;
        }
        p {
            &.subtitle {
                color: $heading-color;
                font-size: 16px;
                font-weight: 400;
                background: rgba(255,26,80,0.03);
                padding: 8px 25px;
                display: inline-block;
                border-radius: 4px;
                span {
                    color: #1c92d2;
                }
            }
        }
        h2 {
            margin-top: 30px;
            margin-bottom: 20px;
            font-size: 36px;
            line-height: 50px;
            font-weight: 500;
            @media #{$lg-layout} {
                margin-top: 7px;
            }
            @media #{$md-layout} {
                margin-top: 7px;
            }
            @media #{$sm-layout} {
                margin-top: 7px;
            }
        }
        p {
            color: rgba(29,29,36,0.75);
            font-size: 18px;
            line-height: 30px;
        }
    }
}


/*================================================
How It's Work Area CSS
=================================================*/
.process-area {
    position: relative;
    z-index: 1;
}
.single-process-box {
    margin-bottom: 30px;
    background-color: $white-color;
    transition: $transition;
    border: 1px solid #f5f3f2;
    border-radius: 5px;
    padding: 50px 20px;
    text-align: center;
    position: relative;
    z-index: 1;

    .image {
        margin-bottom: 25px;
    }
    h3 {
        margin-bottom: 12px;
        font: {
            size: 24px;
        };
        a {
            display: inline-block;
        }
    }
    .number {
        line-height: 1;
        color: #eeedef;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 10px;
        z-index: -1;
        opacity: .45;
        transition: $transition;
        font: {
            size: 215px;
            weight: 800;
            style: italic;
        };
    }
    &:hover {
        border-color: $main-color;

        .number {
            transform: translateX(10px) rotate(5deg);
        }
    }
}
.process-image {
    text-align: center;
}
.process-content {
    .single-box-item {
        margin-bottom: 20px;
        background-color: $white-color;
        box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .02);
        border-radius: 5px;
        position: relative;
        overflow: hidden;
        z-index: 1;
        max-width: 430px;

        .image {
            background-color: #edfcff;
            padding: 15px 20px;
            border-radius: 5px 0 0 5px;
        }
        h3 {
            margin-bottom: 0;
            font-size: 24px;
            padding: 10px 20px 10px 20px;
        }
        .number {
            color: #eeedef;
            line-height: 1;
            position: absolute;
            right: 5px;
            bottom: -45px;
            z-index: -1;
            font: {
                size: 105px;
                style: italic;
                weight: 700;
            };
        }
        &:hover {
            .number {
                animation: bounce 2s;
            }
        }
    }
    .row {
        .col-lg-12 {
            &:nth-child(1) {
                .single-box-item {
                    margin-left: -30px;
                }
            }
            &:nth-child(2) {
                .single-box-item {
                    margin-left: 10px;
                }
            }
            &:nth-child(3) {
                .single-box-item {
                    margin-left: 50px;
                }
            }
            &:nth-child(4) {
                .single-box-item {
                    margin-left: 90px;
                }
            }
            &:nth-child(5) {
                .single-box-item {
                    margin-left: 130px;
                }
            }
            &:nth-child(6) {
                .single-box-item {
                    margin-left: 170px;
                }
            }
            &:last-child {
                .single-box-item {
                    margin-bottom: 0;
                }
            }
        }
    }
}
.how-its-work-content {
    .sub-title {
        position: relative;
        display: block;
        text-transform: uppercase;
        margin-bottom: 15px;
        color: $main-color;
        font: {
            weight: 700;
            size: 15px;
        };
        img {
            margin-right: 5px;
            animation: {
                name: rotateme;
                duration: 40s;
                iteration-count: infinite;
                timing-function: linear;
            };
        }
    }
    h2 {
        margin-bottom: 15px;
        font: {
            size: 44px;
        };
    }
    .inner-box {
        margin-top: 35px;

        .single-item {
            position: relative;
            z-index: 1;
            padding: {
                left: 100px;
                bottom: 30px;
            };
            .count-box {
                width: 65px;
                height: 65px;
                position: absolute;
                left: 8px;
                top: 8px;
                line-height: 65px;
                text-align: center;
                color: $main-color;
                border-radius: 50%;
                background-color: $white-color;
                box-shadow: rgb(237, 241, 254) 0px 0px 0px 7px;
                font: {
                    size: 30px;
                    weight: 800;
                    style: italic;
                };
            }
            h3 {
                margin-bottom: 10px;
                font: {
                    size: 24px;
                };
            }
            &::before {
                content: '';
                position: absolute;
                top: 1px;
                width: 1px;
                height: 100%;
                background-color: $main-color;
                z-index: -1;
                left: 40px;
            }
            &::after {
                content: "\ea17";
                position: absolute;
                left: 26px;
                color: $main-color;
                bottom: 10px;
                animation: fade-down 1.5s infinite linear;
                font: {
                    family: 'boxicons'!important;
                    size: 30px;
                };
            }
            &:last-child {
                padding-bottom: 0;

                &::before {
                    display: none;
                }
                &::after {
                    display: none;
                }
            }
        }
    }
}



/*================================================
History Area CSS
=================================================*/
.history-timeline {
    position: relative;
    list-style-type: none;
    padding-left: 0;
    z-index: 1;
    margin: {
        left: auto;
        right: auto;
        bottom: 0;
    };
    &::before {
        content: '';
        width: 1px;
        height: 100%;
        background-color: #ebebeb;
        top: 0;
        left: 130px;
        position: absolute;
    }
    .timeline-block {
        position: relative;
        padding-left: 165px;
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;

            &::before {
                display: none;
            }
        }
        &::before {
            content: "\ea17";
            position: absolute;
            left: 116px;
            color: $main-color;
            bottom: -25px;
            animation: fade-down 1.5s infinite linear;
            font: {
                family: "boxicons" !important;
                size: 30px;
            };
        }
        .timeline-date {
            text-align: right;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            color: $optional-color;
            font: {
                size: 15px;
            };
            span {
                display: block;
                margin-bottom: 3px;
                color: $black-color;
                font-weight: 700;
            }
            sup {
                color: $black-color;
                font-weight: 600;
            }
        }
        .timeline-icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 126px;

            .dot-badge {
                background-color: $main-color;
                display: block;
                width: 10px;
                height: 10px;
                border-radius: 50%;
            }
        }
        .timeline-content {
            background-color: $white-color;
            padding: 30px;

            .content {
                padding: {
                    left: 5px;
                    right: 15px;
                };
                h3 {
                    margin-bottom: 15px;
                    font-size: 25px;
                }
            }
        }
    }
    &.history-timeline-style-two {
        &::before {
            left: auto;
            right: 130px;
            background-color: #f3f1f1;
        }
        .timeline-block {
            padding: {
                left: 0;
                right: 165px;
            };
            &::before {
                left: auto;
                right: 115px;
            }
            .timeline-date {
                text-align: left;
                right: 0;
                left: auto;
            }
            .timeline-icon {
                left: auto;
                right: 125px;
            }
            .timeline-content {
                background-color: #f7faff;

                .content {
                    padding: {
                        left: 10px;
                        right: 10px;
                    };
                }
            }
        }
    }
}
