$scrub: #C0B3A0;
$dayTime: true;
$dayDuration: 120s;
$foregroundDuration: 30s;
$hillsDuration: 60s;
$rocks1Duration: 120s;
$rocks2Duration: 240s;

.parallax-navbar-wrapper {
body {
  overflow: hidden;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

nav {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

button {
  z-index: 9999999999;
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 18px;
  right: 70px;
  width: 30px;
  height: 70px;
  background: transparent;
  @media #{$sm-layout} {
    height: 50px;
    right: 20px;
    width: 60px;
    position: fixed;
  }
}

ul,
li {
  margin: 0;
  padding: 0;
}

ul {
  padding: 25px;
  position: absolute;
  right: 50px;
  top: 50px;
  width: 100%;
  @media #{$sm-layout} {
    position: fixed;
    top: 50px;
  }
}

li {
  list-style: none;
  display: flex;
  padding: 0px !important;
  margin: 0px !important;
  align-items: center;
  vertical-align: middle;
  white-space: nowrap;
  // cursor: pointer;
  font-family: NHaasGroteskDSPro-65Md, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 3em !important;
  font-weight: 900;
  line-height: 1.5em;
  color: #ffffff;
  @media #{$sm-layout} {
    font-size: 2em !important;
    line-height: 1.8em;
  }
}

.text-placeholder {
    // box-shadow: 5px 5px 5px rgba(8, 8, 8, 0.1);
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  width: 100%;
  height: auto;
  margin:auto;
  flex: 1;
  align-items: center;
  vertical-align: middle;
  text-align: right;
}
}

.parallax-footer-area {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 99999999999;
  /* Set the fixed height of the footer here */
  height: 300px;
}


.scrolling-footer-content {
  z-index: 999999999;
	position:absolute;
	width:100%;
	height:100%;
	overflow:hidden;
}



.navigation-hero {
  width: 100vw;
  height:100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #d9edfd;
  transform: translate3d(0,0,0);
}

@each $index, $speed, $height in (1, 20s, 136px),
                                 (2, 30s, 145px),
                                 (3, 55s, 158px),
                                 (4, 75s, 468px),
                                 (5, 95s, 311px),
                                 (6, 120s, 222px) {
  .layer-#{$index} {
    animation: parallax_fg linear $speed infinite both;
    background:  url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/24650/#{$index}.png) 0 100% repeat-x;
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size:  auto $height;
  }
}

.bike-1, .bike-2 {
  background:  url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/24650/bike.png) 0 100% no-repeat;
  z-index: 1;
  position: absolute;
  bottom: 100px;
  left: 0;
  width: 100%;
  height: 100%;
  background-size:  auto 75px;
  animation: parallax_bike linear 10s infinite both;
}

.bike-2 {
  animation: parallax_bike linear 15s infinite both;
}

@keyframes parallax_fg {
  0% { background-position: 2765px 100%;}
  100% {background-position: 550px 100%; }
}
@keyframes parallax_bike {
  0% { background-position: -300px 100%;}
  100% {background-position: 2000px 100%; }
}
