@font-face {
    font-family: 'CircularStd';
    src: url('/assets/fonts/CircularStd-Book.eot');
    src: url('/assets/fonts/CircularStd-Book.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/CircularStd-Book.woff2') format('woff2'),
        url('/assets/fonts/CircularStd-Book.woff') format('woff'),
        url('/assets/fonts/CircularStd-Book.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'CircularStd';
    src: url('/assets/fonts/CircularStd-BookItalic.eot');
    src: url('/assets/fonts/CircularStd-BookItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/CircularStd-BookItalic.woff2') format('woff2'),
        url('/assets/fonts/CircularStd-BookItalic.woff') format('woff'),
        url('/assets/fonts/CircularStd-BookItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'CircularStd';
    src: url('/assets/fonts/CircularStd-Medium.eot');
    src: url('/assets/fonts/CircularStd-Medium.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/CircularStd-Medium.woff2') format('woff2'),
        url('/assets/fonts/CircularStd-Medium.woff') format('woff'),
        url('/assets/fonts/CircularStd-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'CircularStd';
    src: url('/assets/fonts/CircularStd-BoldItalic.eot');
    src: url('/assets/fonts/CircularStd-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/CircularStd-BoldItalic.woff2') format('woff2'),
        url('/assets/fonts/CircularStd-BoldItalic.woff') format('woff'),
        url('/assets/fonts/CircularStd-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'CircularStd';
    src: url('/assets/fonts/CircularStd-BlackItalic.eot');
    src: url('/assets/fonts/CircularStd-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/CircularStd-BlackItalic.woff2') format('woff2'),
        url('/assets/fonts/CircularStd-BlackItalic.woff') format('woff'),
        url('/assets/fonts/CircularStd-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'CircularStd';
    src: url('/assets/fonts/CircularStd-Black.eot');
    src: url('/assets/fonts/CircularStd-Black.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/CircularStd-Black.woff2') format('woff2'),
        url('/assets/fonts/CircularStd-Black.woff') format('woff'),
        url('/assets/fonts/CircularStd-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'CircularStd';
    src: url('/assets/fonts/CircularStd-Bold.eot');
    src: url('/assets/fonts/CircularStd-Bold.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/CircularStd-Bold.woff2') format('woff2'),
        url('/assets/fonts/CircularStd-Bold.woff') format('woff'),
        url('/assets/fonts/CircularStd-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'CircularStd';
    src: url('/assets/fonts/CircularStd-MediumItalic.eot');
    src: url('/assets/fonts/CircularStd-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/CircularStd-MediumItalic.woff2') format('woff2'),
        url('/assets/fonts/CircularStd-MediumItalic.woff') format('woff'),
        url('/assets/fonts/CircularStd-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Beckman';
    src: url("/assets/fonts/Beckman-Black.otf") format("opentype");
}


@font-face {
    font-family: 'Brolink-Regular';
    src: url('/assets/fonts/Brolink-Regular.otf');
    src: url('/assets/fonts/Brolink-Regular.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Brolink-Outline';
    src: url('/assets/fonts/Brolink-Outline.otf');
    src: url('/assets/fonts/Brolink-Outline.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}


//===== Colors =====//
$theme-color: #50befa;
$body-color: #1d1d24;
$heading-color: #1f1f25;
$border-color: #d4d4d4;
$white:#fff;
$white-color:#fff;
$main-color: $theme-color;
$optional-color: #d4d4d4;
$black-color:#1f1f25;


/*===============================
    Font Family
=================================*/
$body-font: 'quiet-sans', sans-serif;
$heading-font: 'NHaasGroteskDSPro-65Md',"Helvetica Neue",Helvetica,Arial,sans-serif;


// Heading Font List
$font-1: 'Beckman', sans-serif;

$fontList: $font-1;


/*===============================
    Color Variation
=================================*/
$color-1:  $theme-color;
$color-2:  #f4769a;
$color-3:  #2f21b3;
$color-4:  #8956e2;
$colorList: $color-1, $color-2 ,$color-3 ,$color-4;






//===== Line Height =====//
$body-line-height: 2;
//==== Font size =====//
$body-font-size: 18px;
$h1: 64px;
$h2: 54px;
$h3: 24px;
$h4: 20px;
$h5: 18px;
$h6: 16px;

//===== Social Colors =====//
$facebook : #3B5998;
$twitter : #00ACEE;
$google-plus : #DD4B39;
$pinterest : #C8232C;
$linkedin : #0E76A8;
$flickr : #FF0084;
$google : #dd4b39;
$youtube : #cd201f;




// Layouts
$smlg-device: 'only screen and (max-width: 1199px)';

$extra-device: 'only screen and (min-width: 1600px) and (max-width: 1919px)';

$laptop-device: 'only screen and (min-width: 1200px) and (max-width: 1599px)';
$lg-layout: 'only screen and (min-width: 1023px) and (max-width: 1199px)';
$md-layout:'only screen and (min-width: 768px) and (max-width: 1023px)';
$sm-layout:'only screen and (max-width: 767px)';
$large-mobile: 'only screen and (max-width: 575px)';
/* container 450px*/
$small-mobile: 'only screen and (max-width: 479px)';
/* container 300px*/

@media #{$large-mobile} {
    .container {
        width: 450px;
        overflow: hidden;
    }
}

@media #{$small-mobile} {
    .container {
        width: 320px;
        overflow: hidden;
    }
}


$transition: 0.2s;
