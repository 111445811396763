.vfx-call-out {
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  color: #fff;
  float: right;
  font-family: adobe-garamond-pro,"Helvetica Neue",Helvetica,Arial,sans-serif;
  list-style: outside none none;
  padding-left: 14px;
  padding-right: 0;
  text-align: left;
  text-size-adjust: none;
  transform: translate3d(50px,0,0);
  width: 100%;
  // mix-blend-mode: difference;

  h2.home-copy__title.j-fadeslidein-scrollout-trigger {
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
    color: #fff;
    font-family: NHaasGroteskDSPro-65Md,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 48px;
    font-weight: 400;
    line-height: 50px;
    list-style: outside none none;
    margin: 0 0 34px;
    opacity: 1;
    text-align: left;
    text-size-adjust: none;
    transform: translate3d(0,0,0);
  }

  p.home-copy__txt.j-fadeslidein-scrollout-trigger {
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
    color: #fff;
    font-family: adobe-garamond-pro,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 34px;
    list-style: outside none none;
    margin: 0 0 17px;
    opacity: 1;
    text-align: left;
    text-size-adjust: none;
    transform: translate3d(0,0,0);
  }
  a.btn-view {
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: NHaasGroteskDSPro-65Md,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 18px;
    line-height: 34px;
    list-style: outside none none;
    margin: -18px -28px;
    padding: 18px 28px 18px 80px;
    position: relative;
    text-align: left;
    text-decoration: none;
    text-size-adjust: none;
    transition: all .2s;
  }

  a.btn-view:before {
    background-color: #fff;
    content: "";
    height: 2px;
    left: 28px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    transition: all .2s;
    width: 34px;
  }

  a.btn-view:hover {
    outline: 0;
    padding-left: 91px;
  }

  a.btn-view:hover:before {
    width: 50px;
  }

  a.btn-view:active {
    outline: 0;
  }

  a.btn-view:focus {
    outline: 0;
  }
}

.vfx-call-out-right {
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  color: #fff;
  float: right;
  font-family: adobe-garamond-pro,"Helvetica Neue",Helvetica,Arial,sans-serif;
  list-style: outside none none;
  padding-left: 0px;
  padding-right: 14px;
  text-align: right;
  text-size-adjust: none;
  transform: translate3d(50px,0,0);
  width: 100%;
  // mix-blend-mode: difference;

  h2.home-copy__title.j-fadeslidein-scrollout-trigger {
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
    color: #fff;
    font-family: NHaasGroteskDSPro-65Md,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 48px;
    font-weight: 400;
    line-height: 50px;
    list-style: outside none none;
    margin: 0 0 34px;
    opacity: 1;
    text-align: right;
    text-size-adjust: none;
    transform: translate3d(0,0,0);
  }

  p.home-copy__txt.j-fadeslidein-scrollout-trigger {
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
    color: #fff;
    font-family: adobe-garamond-pro,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 24px;
    line-height: 34px;
    list-style: outside none none;
    margin: 0 0 17px;
    opacity: 1;
    text-align: right;
    text-size-adjust: none;
    transform: translate3d(0,0,0);
  }
  a.btn-view {
    text-align: right;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: NHaasGroteskDSPro-65Md,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 18px;
    line-height: 34px;
    list-style: outside none none;
    margin: -18px 28px;
    padding: 18px 28px 18px 80px;
    position: relative;
    text-align: right;
    text-decoration: none;
    text-size-adjust: none;
    transition: all .2s;
  }

  a.btn-view:before {
    background-color: #fff;
    content: "";
    height: 2px;
    right: -28px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    transition: all .2s;
    width: 34px;
  }

  a.btn-view:hover {
    outline: 0;
    padding-right: 50px;
  }

  a.btn-view:hover:before {
    width: 50px;
  }

  a.btn-view:active {
    outline: 0;
  }

  a.btn-view:focus {
    outline: 0;
  }
}


.animus-vfx-menu {
  // mix-blend-mode: difference;
  z-index: 1;
  font-family: NHaasGroteskDSPro-65Md,"Helvetica Neue",Helvetica,Arial,sans-serif;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  color: #ffffff;
  padding: 50px;
  pointer-events: none;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: row;
  font-size: 12px;
  line-height: 1.5em;

.brand span {
  font-family: 'Beckman', sans-serif;
  font-weight: 900;
}

.description span {
  font-family: NHaasGroteskDSPro-65Md,"Helvetica Neue",Helvetica,Arial,sans-serif;
}

.date {
  font-family: NHaasGroteskDSPro-65Md,"Helvetica Neue",Helvetica,Arial,sans-serif;
}

a {
  font-family: NHaasGroteskDSPro-65Md,"Helvetica Neue",Helvetica,Arial,sans-serif;
}

}

.animus-vfx-menu > div {
  word-wrap: none;
  word-break: none;
  white-space: pre;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  font-weight: 200;
}

.animus-vfx-menu > div b {
  font-weight: 600;
  color: #ffffff;
}

.animus-vfx-menu a {
  pointer-events: all;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  font-weight: 200;
}

.animus-vfx-menu a:hover {
  text-decoration: underline;
  color: inherit;
}

.animus-vfx-menu-dark {
  mix-blend-mode: difference;
  z-index: 1;
  font-family: NHaasGroteskDSPro-65Md,"Helvetica Neue",Helvetica,Arial,sans-serif;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  color: #ffffff;
  padding: 50px;
  pointer-events: none;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: row;
  font-size: 12px;
  line-height: 1.5em;

.brand span {
  font-family: 'Beckman', sans-serif;
  font-weight: 900;
}

.description span {
  font-family: NHaasGroteskDSPro-65Md,"Helvetica Neue",Helvetica,Arial,sans-serif;
}

.date {
  font-family: NHaasGroteskDSPro-65Md,"Helvetica Neue",Helvetica,Arial,sans-serif;
}

a {
  font-family: NHaasGroteskDSPro-65Md,"Helvetica Neue",Helvetica,Arial,sans-serif;
}

}

.animus-vfx-menu-dark > div {
  word-wrap: none;
  word-break: none;
  white-space: pre;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  font-weight: 200;
  mix-blend-mode: difference;

}

.animus-vfx-menu-dark > div b {
  font-weight: 600;
  color: #fff;
}

.animus-vfx-menu-dark a {
  pointer-events: all;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  font-weight: 200;
}

.animus-vfx-menu-dark a:hover {
  text-decoration: underline;
  color: inherit;
}

.loading-wrapper {
  background: #171717;
  background: #171717;
  background-size: cover;
  z-index: 5;
}

.loading {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #171717;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.loading-title {
  font-family: NHaasGroteskDSPro-65Md,"Helvetica Neue",Helvetica,Arial,sans-serif;
  position: absolute;
  top: 40%; left: 50%;
  transform: translate(-50%, -50%);
  width: 30%; height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 6;
  font-weight: 200;
}

.loading-finished {
  font-family: NHaasGroteskDSPro-65Md,"Helvetica Neue",Helvetica,Arial,sans-serif;
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);
  width: 30%; height: auto;
  background: #171717;
  background-size: cover;
  overflow: visible;
  background-position: center;
  z-index: 5;
}

.loading-finished-text {
  font-family: adobe-garamond-pro,"Helvetica Neue",Helvetica,Arial,sans-serif;
  position: absolute;
  font-weight: 600;
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);
  width: 80%; height: auto;
  z-index: 5;
  font-size: 0.8rem !important;
}

.finished-text-title {
  font-family: NHaasGroteskDSPro-65Md,"Helvetica Neue",Helvetica,Arial,sans-serif;
}

.loading-text-button {
  align-self: center;
  position: absolute;
  border-style: solid;
  border-color: #ffffff;
  width: 320px;
  top: calc(50% - 160px) ;
  left: calc(50% - 160px);
  height: 320px;
  border-width: 1px;
  z-index: 5;
  border-radius: 50%;
}

.loading-bar-container {
  width: 200px;
  height: 3px;
  z-index: 5;
  background: #272727;
}

.loading-bar {
  height: 3px;
  z-index: 5;
  background: white;
}

.animus-menu {
  font-family: 'Beckman';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  color: #808080;
  padding: 40px;
  pointer-events: none;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: row;
  font-size: 10px;
  line-height: 1.5em;
}

.animus-menu > div {
  word-wrap: none;
  word-break: none;
  white-space: pre;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.animus-menu > div b {
  font-weight: 600;
  color: #b0b0b0;
}

.animus-menu a {
  pointer-events: all;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

.animus-menu a:hover {
  text-decoration: underline;
  color: inherit;
}

/* #root {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  font-family: 'Inter var', sans-serif;
  color: white;
  background: #101010;
  user-select: none;
} */

.fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s;
}

.fullscreen-button-down {
  z-index: 2;
  position: absolute;
  bottom: 0%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: all 1s;
  // @media #{$sm-layout} {
  //
  // }
}

.fullscreen-button-up {
  z-index: 2;
  position: absolute;
  top: 0%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: all 1s;
}

.scrolling-button {
  z-index: 2;
  border: none;
  font-size: 3rem;
  font-weight: 200;
  -webkit-animation: heartbeat 2s ease-in-out infinite both;
          animation: heartbeat 2s ease-in-out infinite both;

}

.bg {
  background: #101010;
}

.fullscreen.notready {
  color: #606060;
}

.menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  color: #808080;
  padding: 40px;
  pointer-events: none;
  justify-content: center;
  align-items: flex-end;
  flex-direction: row;
  font-size: 10px;
  line-height: 1.5em;
}

.menu.left {
  justify-content: flex-start;
}

.menu.right {
  justify-content: flex-end;
}

.menu > div {
  word-wrap: none;
  word-break: none;
  white-space: pre;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.menu > div b {
  font-weight: 600;
  color: #b0b0b0;
}

.fullscreen.ready > div {
  cursor: pointer;
}

.fullscreen.clicked {
  pointer-events: none;
  opacity: 0;
}

.menu a {
  pointer-events: all;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}
.menu a:hover {
  text-decoration: underline;
  color: inherit;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-5-28 12:40:5
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

.main-buttons {
a.btn-view {
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: NHaasGroteskDSPro-65Md,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 18px;
  line-height: 34px;
  list-style: outside none none;
  margin: -18px -28px;
  padding: 18px 28px 18px 80px;
  position: relative;
  text-align: left;
  text-decoration: none;
  text-size-adjust: none;
  transition: all .2s;
}

a.btn-view:before {
  background-color: #fff;
  content: "";
  height: 2px;
  left: 28px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  transition: all .2s;
  width: 34px;
}

a.btn-view:hover {
  outline: 0;
  padding-left: 91px;
}

a.btn-view:hover:before {
  width: 50px;
}

a.btn-view:active {
  outline: 0;
}

a.btn-view:focus {
  outline: 0;
}
}

.technologyIcons {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .technologyIcons-canvas {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: auto;
    height: 100vh !important;
    margin-top: -40vh !important;
    .technologyIcons-icons {
      width: 100%;
      height: 100%;
    }
  }
}

.service-wrapper {
  a {
  position: relative;
  display: inline-block;
  padding: 15px 30px;
  color: #2196f3;
  text-transform: uppercase;
  letter-spacing: 4px;
  text-decoration: none;
  font-size: 24px;
  overflow: hidden;
  transition: 0.2s;
  cursor: pointer;
}
a:hover {
  color: #255784;
  background: #2196f3;
  box-shadow: 0 0 10px #2196f3, 0 0 40px #2196f3, 0 0 80px #2196f3;
  transition-delay: 1s;
}
a span {
  position: absolute;
  display: block;
}
a span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #2196f3);
}
a:hover span:nth-child(1) {
  left: 100%;
  transition: 1s;
}
a span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #2196f3);
}
a:hover span:nth-child(3) {
  right: 100%;
  transition: 1s;
  transition-delay: 0.5s;
}
a span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #2196f3);
}
a:hover span:nth-child(2) {
  top: 100%;
  transition: 1s;
  transition-delay: 0.25s;
}
a span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #2196f3);
}
a:hover span:nth-child(4) {
  bottom: 100%;
  transition: 1s;
  transition-delay: 0.75s;
}
}
