.video-background {
  align-items: center;
  opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    min-height: 100vh;
    @media #{$sm-layout} {
      display: none;
    }
    @media #{$md-layout} {
      transform: scale(3,3);
      height: auto;
      overflow: auto;
      margin: auto;
      top: 0; left: 0;
    	bottom: 0; right: 0;
    }
    @media #{$lg-layout} {
        transform: scale(1.4,1.4);
      overflow: hidden;
      align-items: center;
      opacity: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.video-background-dark {
  overflow: hidden;
  align-items: center;
  opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @media #{$sm-layout} {
      display: none;
    }
    @media #{$md-layout} {
      transform: scale(3,3);
      height: auto;
      overflow: auto;
      margin: auto;
      top: 0; left: 0;
    	bottom: 0; right: 0;
    }
    @media #{$lg-layout} {
      transform: scale(1.4,1.4);
      overflow: hidden;
      align-items: center;
      opacity: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.video-background-dark::after {
    content: ""; // ::before and ::after both require content
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(-120deg, #2da7cc 0%, #2da7cc 50%,  rgba(0,0,0,0) 100%);
    opacity: .7;
    @media #{$md-layout} {
      top: calc(100vh / 3);
      left: calc(100vw / 3);
      width: calc(100vw / 3);
      height: calc(100vh / 3);
      overflow: hidden;
      background-image: linear-gradient(120deg, #2da7cc 0%, #2da7cc 30%,  rgba(0,0,0,0) 100%);
    }
    @media #{$lg-layout} {
      top: calc(100vh / 1.4);
      left: calc(100vw / 1.4);
      width: calc(100vw / 1.4);
      height: calc(100vh / 1.4);
      overflow: hidden;
      background-image: linear-gradient(-120deg, #2da7cc 0%, #2da7cc 20%,  rgba(0,0,0,0) 100%);
    }
}

.videobackground-gradient {
  // background-image: linear-gradient(to left, rgba(0,0,0,0), rgba(0,0,0,0.9));
  // background-image: #000000;
  // width: 100%;
  // height: 100vh;
  // min-height: 100vh;
  // object-fit: cover;
  // position: relative;
  // z-index: 99999999999999999 !important;
  h2 {
    vertical-align: middle !important;
  }
}
