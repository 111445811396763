/*===================
    Section Title
====================*/

.section-title {
    h1{
        &.title{
            padding-left: 0px !important;
            margin-left: 0px !important;
            font-weight: 900;
            font-size: 100px;
            color: #ffffff;
            margin-bottom: 8px;
            @media #{$lg-layout} {
                font-size: 100px;
                font-weight: 900;
            }
            @media #{$md-layout} {
                font-size: 80px;
                margin-bottom: 7px;
            }
            @media #{$sm-layout} {
                font-size: 50px;
                margin-bottom: 7px;
            }
        }
    }
    h2{
      color: #ffffff;
        &.title{
            font-size: 60px;
            margin-bottom: 8px;
            padding-left: 0px !important;
            margin-left: 0px !important;
            @media #{$lg-layout} {
                font-size: 50px;
                text-align: left;
            }
            @media #{$sm-layout} {
                font-size: 40px;
                margin-bottom: 7px;
                text-align: left;
            }
        }
        &.software-title{
          color: #ffffff;
            vertical-align: middle;
            padding-top: 150px;
            font-size: 60px;
            margin-bottom: 8px;
            text-align: left;
            @media #{$lg-layout} {
                font-size: 50px;
            }
            @media #{$md-layout} {
              padding-top: 50px;
              text-align: center;
              font-size: 50px;
              margin-bottom: 7px;
            }
            @media #{$sm-layout} {
                padding-top: 20px;
                text-align: center;
                font-size: 40px;
                margin-bottom: 7px;
            }
        }
        &.software-title-right{
          color: #ffffff;
            vertical-align: middle;
            padding-top: 150px;
            font-size: 60px;
            margin-bottom: 8px;
            text-align: right;
            @media #{$lg-layout} {
                font-size: 50px;
            }
            @media #{$md-layout} {
              padding-top: 50px;
              text-align: center;
              font-size: 50px;
              margin-bottom: 7px;
            }
            @media #{$sm-layout} {
                padding-top: 20px;
                text-align: center;
                font-size: 40px;
                margin-bottom: 7px;
            }
        }
    }
    p {
        font-size: 18px;
        line-height: 30px;
        color: #ffffff;
        a {
            color: #ffffff;
            font-weight: 500;
            &:hover {
                color: $theme-color;
            }
        }
        br {
            @media #{$sm-layout} {
                display: none;
            }
        }
    }

    h3 {
        padding-left: 0px !important;
        margin-left: 0px !important;
        font-size: 36px;
        line-height: 26px;

        @media #{$sm-layout} {
            font-size: 26px;
            margin-bottom: 8px;
        }
    }
    .service-btn{
        margin-top: 36px;
        @media #{$sm-layout} {
            margin-top: 12px;
        }
    }

    &.service-style--2 {
        h2 ,
        h3 {
            color: #ffffff;
        }
        p{
            padding: 0 21%;
            font-weight: 300;
            color: #ffffff;
            @media #{$md-layout} {
                padding: 0 7%;
            }
            @media #{$sm-layout} {
                padding: 0;
            }
        }
    }

    &.service-style--3{
        h2 {
          color: #ffffff;
        }
        p {
            padding: 0 21%;
            font-weight: 300;
            color: #ffffff;
            @media #{$md-layout} {
                padding: 0 7%;
            }
            @media #{$sm-layout} {
                padding: 0;
            }
        }
        &.text-left {
            p {
                padding: 0 3% 0 0;
            }
        }
    }
}
.section-title-default {
    h2 {
      color: #ffffff;
        &.title {
            line-height: 1.34;
            font-size: 48px;
            @extend %fontWeight400;
        }
    }
}


.section-title {
    h2{
      color: #ffffff;
        @media #{$sm-layout} {
            font-size: 36px;
            margin-bottom: 7px;
        }
    }
}
