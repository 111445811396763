// All Css Here
@import '~bootstrap/dist/css/bootstrap.min.css';
@import "../public/assets/css/plugins.css";
@import "../public/assets/css/flaticon.css";
@import "../public/assets/css/boxicons.min.css";
@import "../public/assets/scss/style";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@font-face {
    font-family: 'CircularStd';
    src: url('/assets/fonts/CircularStd-Book.eot');
    src: url('/assets/fonts/CircularStd-Book.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/CircularStd-Book.woff2') format('woff2'),
        url('/assets/fonts/CircularStd-Book.woff') format('woff'),
        url('/assets/fonts/CircularStd-Book.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'CircularStd';
    src: url('/assets/fonts/CircularStd-BookItalic.eot');
    src: url('/assets/fonts/CircularStd-BookItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/CircularStd-BookItalic.woff2') format('woff2'),
        url('/assets/fonts/CircularStd-BookItalic.woff') format('woff'),
        url('/assets/fonts/CircularStd-BookItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'CircularStd';
    src: url('/assets/fonts/CircularStd-Medium.eot');
    src: url('/assets/fonts/CircularStd-Medium.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/CircularStd-Medium.woff2') format('woff2'),
        url('/assets/fonts/CircularStd-Medium.woff') format('woff'),
        url('/assets/fonts/CircularStd-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'CircularStd';
    src: url('/assets/fonts/CircularStd-BoldItalic.eot');
    src: url('/assets/fonts/CircularStd-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/CircularStd-BoldItalic.woff2') format('woff2'),
        url('/assets/fonts/CircularStd-BoldItalic.woff') format('woff'),
        url('/assets/fonts/CircularStd-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'CircularStd';
    src: url('/assets/fonts/CircularStd-BlackItalic.eot');
    src: url('/assets/fonts/CircularStd-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/CircularStd-BlackItalic.woff2') format('woff2'),
        url('/assets/fonts/CircularStd-BlackItalic.woff') format('woff'),
        url('/assets/fonts/CircularStd-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'CircularStd';
    src: url('/assets/fonts/CircularStd-Black.eot');
    src: url('/assets/fonts/CircularStd-Black.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/CircularStd-Black.woff2') format('woff2'),
        url('/assets/fonts/CircularStd-Black.woff') format('woff'),
        url('/assets/fonts/CircularStd-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'CircularStd';
    src: url('/assets/fonts/CircularStd-Bold.eot');
    src: url('/assets/fonts/CircularStd-Bold.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/CircularStd-Bold.woff2') format('woff2'),
        url('/assets/fonts/CircularStd-Bold.woff') format('woff'),
        url('/assets/fonts/CircularStd-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'CircularStd';
    src: url('/assets/fonts/CircularStd-MediumItalic.eot');
    src: url('/assets/fonts/CircularStd-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/CircularStd-MediumItalic.woff2') format('woff2'),
        url('/assets/fonts/CircularStd-MediumItalic.woff') format('woff'),
        url('/assets/fonts/CircularStd-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Beckman';
    src: url("/assets/fonts/Beckman-Heavy.otf") format("opentype");
}
