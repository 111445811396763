.iphone {
.iphonex {
	transform: scale(0.8) !important;
	width: 20em;
	height: 40em;
	display: flex;
	transform: rotate(0deg);
	transition: all cubic-bezier(0.36, 0.41, 0.38, 1) 0.4s;
	z-index: 10;
}
.iphonex:hover{
	transform: rotate(0deg) scale(0.95);
	margin-right: 0%;
}
.iphonex:hover ~ .title{
	opacity: 0;
}
.iphonex .back {
	display: flex;
	flex: 1;
	margin: 0.2em;
	border-radius: 3em;
	position: absolute;
	z-index: 1;
	width: calc( 100% - 0.6em );
	height: calc( 100% - 0.6em );
	top: 0;
	right: 0;
	box-shadow: 0 0 10em rgba(70, 70, 70, 0.1);
	transition: all 0.4s;
}
.iphonex:hover .back{
	box-shadow: 0 0 15em rgba(70, 70, 70, 0.4);
}
.iphonex .front {
	display: flex;
	flex: 1;
	background-color: #292c2d;
	border-radius: 3em;
	margin: 0.2em;
	overflow: hidden;
	position: relative;
	box-shadow: 0 0.1em 0.4em rgba(255, 255, 255, 0.1) inset;
	z-index: 10;
}
.iphonex .screen{
	display: flex;
	flex: 1;
	background-color: #191b1c;
	margin: 0.4em;
	border-radius: 2.6em;
	border: solid 0.2em #121415;
	position: relative;
	z-index: 10;
}
.iphonex .front__line{
	background: linear-gradient(to right, #515455, #454748, #515455);
	position: absolute;
	z-index: 8;
	width: 100%;
	height: 0.5em;
	top: 10%
}
.iphonex .front__line-second{
	top: 90%
}
.iphonex .screen .screen__view{
	display: flex;
	flex: 1;
	margin: 0.6em;
	border-radius: 2em;
	overflow: hidden;
	position: relative;
	width: 100%;
	position: relative;
	align-items: center;
	justify-content: center;
}
.iphonex .screen .screen__view:after,.iphonex .screen .screen__view:before{
	content: "";
	position: absolute;
	z-index: 1;
	width: 50em;
	height: 50em;
	border-radius: 50%;
	background: linear-gradient(110deg,#000000, #0787ef, #000000 );
	bottom: 0;
}
.iphonex .screen .screen__view:after{
	transform:  translateY(100%) scaleX(1.4);
	opacity: 0.3;
	transition: all ease 0.5s 0.1s;
}
.iphonex .screen:hover .screen__view:after{
	transform:  translateY(10%) scaleX(1.4);
	transition: all ease 0.9s;
}
.iphonex .screen .screen__view:before{
	transform:  translateY(100%) scaleX(1.4);
	opacity: 1;
	z-index: 40;
	transition: all ease 0.6s;
}
.iphonex .screen:hover .screen__view:before{
	transform:  translateY(10%) scaleX(1.4);
	transition: all ease 0.9s 0.2s;
}
.iphonex .screen .screen__view .animusLogo{
	width: 100%;
	height: 100%;
	background-image: url('https://animusdevelopment.b-cdn.net/images/about/animus_monkey_4.png');
  background-repeat: no-repeat;
	background-position: center;
	position: absolute;
	z-index: 10;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all ease 0.7s;
	button {
		margin-top: 350px;
	}
}
.iphonex .screen .screen__view .animusLogo.white{
	width: 0.9em;
	height: 0.9em;
	border: solid 0.07em #fff;
	position: relative;
	margin-left: 0.3em;
	background: transparent;
	box-sizing: border-box;
}
.iphonex .screen:hover .screen__view .animusLogo:not(.white){
	transform: translateY(100%) translateZ(1px);
	transition: all ease 1s 0.1s
}
.iphonex .screen .screen__view .animusLogo:after,
.iphonex .screen .screen__view .animusLogo:before,
.iphonex .screen .screen__view .animusLogo.white:after,
.iphonex .screen .screen__view .animusLogo.white:before,
.iphonex .screen .screen__view .animusLogo.white span{
	border: solid 0.04em #fff;
}
.iphonex .screen .screen__view .animusLogo:after{
	bottom: 0;
	transform: translateY(63%) scaleY(1.65) scaleX(1.5) rotate(-30deg);
	transform-origin: top center;
}
.iphonex .screen .screen__view .animusLogo:before{
	top: 0;
	transform: translateY(-62%) scaleY(1.65) scaleX(1.5) rotate(-30deg);
	transform-origin: bottom center;
}
.iphonex .screen .screen__view .animusLogo span{
	top: 0;
	transform: translateY(-50%) scaleY(1.85) scaleX(1.5) rotate(-63deg);
	transform-origin: bottom right;
}
.iphonex .screen .screen__view .hello{
	font-size: 2.5em;
	color: #fff;
	position: absolute;
	z-index: 60;
	opacity: 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	transition: all ease 0.3s;
	transform: translateY(40%);
	font-weight: 600;
	#input {
		top: 75%;
		input[type=text]{
		  border-radius:10px;
		}
		input[type=password]{
			border-radius:10px;
		}
		::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		  color: #fff;
		}
		::-moz-placeholder { /* Firefox 19+ */
		  color: #fff;
		}
		:-ms-input-placeholder { /* IE 10+ */
		  color: #fff;
		}
		:-moz-placeholder { /* Firefox 18- */
		  color: #fff;
		}
	}
}
.iphonex .screen:hover .screen__view .hello{
	opacity: 1;
	transform: translateY(0%);
	transition: all ease 0.6s 0.7s;
}
.iphonex .screen .screen__front{
	position: absolute;
	width: 50%;
	background-color: #191b1c;
	height: 1.8em;
	border-radius: 0 0 0.9em 0.9em;
	right: 25%;
	top: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 0.3em;
	box-sizing: border-box;
	margin-top: 0.5em;
	z-index: 999;
}
.iphonex .screen .screen__front::after,.iphonex .screen .screen__front::before{
	content: "";
	width: 10%;
	height: 50%;
	position: absolute;
	background: transparent;
	top: -0.3em;
	border: solid 0.4em #191b1c;
	border-bottom: 0;
}
.iphonex .screen .screen__front::after{
	left:0.4em;
	transform: translateX(-100%);
	border-left: 0;
	border-radius: 0 0.7em 0 0;
}
.iphonex .screen .screen__front::before{
	right:0.4em;
	transform: translateX(100%);
	border-right: 0;
	border-radius: 0.7em 0 0 0;
}
.iphonex .screen .screen__front-speaker{
	background: #070808;
	border-radius: 0.2em;
	height: 0.35em;
	width: 28%;
}
.iphonex .screen .screen__front-camera{
	height: 0.35em;
	width: 0.35em;
	background: #272727;
	margin-left: .5em;
	border-radius: 50%;
	margin-right: -0.8em;
}
.iphonex .phoneButtons{
	width: 1em;
	height: 6em;
	position: absolute;
	z-index: 2;
	background: linear-gradient(to bottom, #212324, #2b2e31,#212324);
	box-shadow: 0 0 0.4em rgba(255, 255, 255, 0.1) inset;
	border-radius: 2px;
}
.iphonex .phoneButtons-right{
	right: 0;
	top: 30%;
}
.iphonex .phoneButtons-left{
	left: 0;
	top: 15%;
	height: 3em;
}
.iphonex .phoneButtons-left2{
	left: 0;
	top: 25%;
	height: 5em;
}
.iphonex .phoneButtons-left3{
	left: 0;
	top: calc( 25% + 6em);
	height: 5em;
}

@media (max-height: 40em){
	.iphonex {
		width: 48vh;
		height: 94vh;
	}
	.title .title__svg svg text{
		font-size: 4em;
	}
}
}

.heartbeat {
	-webkit-animation: heartbeat 1.5s ease-in-out infinite both;
	        animation: heartbeat 1.5s ease-in-out infinite both;
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
