.navbar-wrapper {
body {
  overflow: hidden;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

nav {
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  width: 300px;
}

.background {
  z-index: 9999999;
  position: absolute;
  top: 0;
  right:0;
  bottom: 0;
  width: 300px;
  overflow:visible;
  background: #080808;
  @media #{$sm-layout} {
    position: fixed;
  }
}

button {
  z-index: 9999999999;
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 18px;
  right: 70px;
  width: 30px;
  height: 70px;
  background: transparent;
  @media #{$sm-layout} {
    height: 50px;
    right: 20px;
    width: 60px;
    position: fixed;
  }
}

ul,
li {
  margin: 0;
  padding: 0;
}

ul {
  padding: 25px;
  position: absolute;
  right: 0;
  top: 100px;
  width: 300px;
  @media #{$sm-layout} {
    position: fixed;
    top: 50px;
  }
}

li {
  list-style: none;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  vertical-align: middle;
  white-space: nowrap;
  // cursor: pointer;
  @media #{$sm-layout} {
    margin-bottom: 10px;
  }
}

.icon-placeholder {
  box-shadow: 5px 5px 5px rgba(8, 8, 8, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex: 40px 0;
  margin-right: 20px;
  float:left;
  align-items: center;
  vertical-align: middle;
  text-align: center;
}

.text-placeholder {
    box-shadow: 5px 5px 5px rgba(8, 8, 8, 0.1);
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  width: 250px;
  height: auto;
  margin:auto;
  flex: 1;
  align-items: center;
  vertical-align: middle;
  text-align: center;
}

.caption {
  font-size: 14px;
  position: absolute;
  margin-bottom: 20px;
  padding: 25px;
  bottom: 0;
  text-align: center;
  color: #d7d7d7;
  @media #{$sm-layout} {
    font-size: 10px;
  }
}
}

.navbar-wrapper-fixed {
body {
  overflow: hidden;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

nav {
  position: fixed;
  top: 0;
  right: 15px;
  bottom: 0;
  width: 300px;
}

.background {
  z-index: 9999999;
  position: fixed;
  top: 0;
  right:0;
  bottom: 0;
  width: 300px;
  overflow:visible;
  background: #080808;
  @media #{$sm-layout} {
    position: fixed;
  }
}

button {
  z-index: 9999999999;
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: fixed;
  top: 18px;
  right: 70px;
  width: 30px;
  height: 70px;
  background: transparent;
  @media #{$sm-layout} {
    height: 50px;
    right: 20px;
    width: 60px;
    position: fixed;
  }
}

ul,
li {
  margin: 0;
  padding: 0;
}

ul {
  padding: 25px;
  position: fixed;
  right: 0;
  top: 100px;
  width: 300px;
  @media #{$sm-layout} {
    position: fixed;
    top: 50px;
  }
}

li {
  list-style: none;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  vertical-align: middle;
  white-space: nowrap;
  // cursor: pointer;
  @media #{$sm-layout} {
    margin-bottom: 10px;
  }
}

.icon-placeholder {
  box-shadow: 5px 5px 5px rgba(8, 8, 8, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex: 40px 0;
  margin-right: 20px;
  float:left;
  align-items: center;
  vertical-align: middle;
  text-align: center;
}

.text-placeholder {
    box-shadow: 5px 5px 5px rgba(8, 8, 8, 0.1);
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  width: 250px;
  height: auto;
  margin:auto;
  flex: 1;
  align-items: center;
  vertical-align: middle;
  text-align: center;
}

.caption {
  font-size: 14px;
  position: fixed;
  margin-bottom: 20px;
  padding: 25px;
  bottom: 0;
  text-align: center;
  color: #d7d7d7;
  @media #{$sm-layout} {
    font-size: 10px;
  }
}
}
