
.frame {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: 2.5rem;
  z-index: 2;
}

.frame__title {
  font-size: inherit;
  font-weight: normal;
  margin: 0;
  cursor: pointer;
} 
