/*===================
Blog Styles
=====================*/
.blog {
  background-image: url(/assets/images/blog/bubble.svg);
  background-repeat: repeat;
}

.blog-style--1 {
  box-shadow: 0 10px 25px 10px rgba(0, 0, 0, 0.5);
    position: relative;
    min-height: 532px;
    max-height: 532px;
    margin-bottom: 60px;
    @extend %transition;
    overflow: hidden;
    border-radius: 6px;
    .thumbnail {
        a {
            display: block;
            position: relative;
            z-index: 1;
            img{
              min-height: 150%;
              min-width: 150%;
              width:auto;
              overflow: hidden;
                @extend %transition;
            }
            &::after {
                background: linear-gradient(to bottom, rgba(0,0,0,0.5) 0, #000000 70%);
                position: absolute;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                content: "";
                z-index: 1;
                opacity: 0;
                @extend %transition;
                @media #{$large-mobile} {
                    opacity: 0.25;
                    top: 0;
                }

            }
        }
    }
    .content{
        position: absolute;
        min-height: 100%;
        bottom: -250px;
        width: 100%;
        padding: 50px 35px;
        z-index: 3;
        @extend %transition;
        @media #{$large-mobile} {
          bottom: -20%;
        }
        @media #{$large-mobile} {
            padding: 30px 20px;
        }
        &::before {
            position: absolute;
            content: "";
            background: linear-gradient(to bottom, #1d1d24 0, #000000 70%);
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 1;
            @extend %transition;
            z-index: -1;
            @media #{$large-mobile} {
                opacity: 1;
            }
        }
        p{
            &.blogtype{
                font-size: 14px;
                margin-bottom: 5px;
                color: #c6c9d8;
            }
        }
        h4{
            &.title{
                margin-bottom: 0;
                a{
                    color: #ffffff;
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 36px;
                    @extend %transition;
                }
            }
        }
        .blog-btn {
            @extend %transition;
            margin-top: 35px;
            transition: 0.7s;
            opacity: 0;
            visibility: hidden;
            color: #1f1f25;

            @media #{$large-mobile} {
                margin-top: 22px;
            }
            @media #{$large-mobile} {
                opacity: 1;
                visibility: visible;
            }
        }
    }
    &:hover {
        .content {
            bottom: 0;
            &::before{
                opacity: 1;
            }
        }
        .thumbnail {
            a {
                img{
                    transform: scale(1.1);
                }
                &::before{
                    opacity: 0;
                }
                &::after{
                    opacity: 0.85;
                    top: 0;
                }
            }
        }
        .content{
            .blog-btn {
              color: #1f1f25;
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
