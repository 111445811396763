.magic {
  body {
    min-height: 100vh;
    padding: 0;
    margin: 0;
  }

  * {
    box-sizing: border-box;
  }

  body {
    background: linear-gradient(250deg, #7b2ff7, #f107a3);
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .App {
    font-family: sans-serif;
    text-align: center;
  }

  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul {
    width: 300px;
    display: flex;
    flex-direction: column;
    background: white;
    padding: 20px;
    border-radius: 25px;
  }

  li {
    background-color: rgba(214, 214, 214, 0.5);
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    cursor: pointer;
  }

  li:last-child {
    margin-bottom: 0px;
  }

  .avatar {
    width: 40px;
    height: 40px;
    background-color: #666;
    border-radius: 20px;
  }

  .row {
    width: 100%;
    height: 8px;
    background-color: #999;
    border-radius: 10px;
    margin-top: 12px;
  }

}
